import React from 'react';

import { Typography } from '@mui/material';

import { HorizontallyCenteredContainer } from './styledComponents/Containers';

function NoResultsText({ mainText, subtitleText = null }) {
  return (
    <HorizontallyCenteredContainer style={{ padding: '30px' }}>
      <div>
        <Typography align='center'>{mainText}</Typography>
        <Typography align='center' variant='body2'>
          {subtitleText}
        </Typography>
      </div>
    </HorizontallyCenteredContainer>
  );
}

export default NoResultsText;
