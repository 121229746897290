import React from 'react';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment } from '@mui/material';

import { StyledTextField } from '../styledComponents/Input';

function PasswordInput({ showPassword, setShowPassword, setPassword, label = 'Password', onKeyPress }) {
  return (
    <StyledTextField
      width={300}
      id='outlined-password-input'
      label={label}
      required={true}
      type={showPassword ? 'string' : 'password'}
      autoComplete='current-password'
      onChange={(e) => setPassword(e.target.value)}
      onKeyDown={onKeyPress}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordInput;
