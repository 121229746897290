import { TextField } from '@mui/material';
import styled, { css } from 'styled-components';

export const StyledTextField = styled(TextField)`
  ${(props) => css`
    width: ${(props) => (props.width ? props.width + 'px' : '100%')};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};

    && {
      margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '3px')};
    }
  `}
`;
