import { makeApiReq } from './internal_api';

export const fetchRecommendations = async (setRecommendations, n, isIgnoreImageless, setIsRecLoaded, dispatchFn) => {
  const token = window.localStorage.getItem('token');
  const resp = await makeApiReq(
    '/recommendation?' +
      new URLSearchParams({
        n: n,
        is_ignore_imageless: isIgnoreImageless,
      }),
    null,
    'GET',
    token,
  );
  if (resp.status === 200) {
    const data = await resp.json();
    if (dispatchFn) {
      dispatchFn(data.movies);
      setIsRecLoaded(true);
    } else if (setRecommendations) {
      setRecommendations(data.movies);
    }
  }
};

export const fetchNowshowing = async (setNowshowing, n, dispatchFn) => {
  const token = window.localStorage.getItem('token');
  const resp = await makeApiReq(
    '/movie/nowshowing?' +
      new URLSearchParams({
        n: n,
      }),
    null,
    'GET',
    token,
  );
  if (resp.status === 200) {
    const data = await resp.json();
    if (dispatchFn) {
      dispatchFn(data.movies);
    } else if (setNowshowing) {
      setNowshowing(data.movies);
    }
  }
};

export const fetchWatchlist = async (setWatchlist, username, setIsWatchlistLoaded, dispatchFn) => {
  const token = window.localStorage.getItem('token');
  let route = username ? `/watchlist?username=${username}` : '/watchlist';
  const resp = await makeApiReq(route, null, 'GET', token);
  if (resp.status === 200) {
    const data = await resp.json();
    if (dispatchFn) {
      dispatchFn(data.watchlist);
      setIsWatchlistLoaded(true);
    } else if (setWatchlist) {
      setWatchlist(data.watchlist);
    }
  }
};
