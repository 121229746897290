import React from 'react';

import { ThemeProvider, responsiveFontSizes } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { StoreProvider } from './util/store';

import GlobalStyles from './GlobalStyles';
import Navigation from './pages/Navigation';
import theme from './theme';

/** Root component which wraps Navigation routes in a
 * StoreProvider, BrowserRouter, themeProvider, SnackbarProvider */
function App() {
  const responsiveTheme = responsiveFontSizes(theme);
  return (
    <StoreProvider>
      <GlobalStyles></GlobalStyles>
      <BrowserRouter>
        <ThemeProvider theme={responsiveTheme}>
          <StyledThemeProvider theme={responsiveTheme}>
            <SnackbarProvider maxSnack={3}>
              <Navigation />
            </SnackbarProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </BrowserRouter>
    </StoreProvider>
  );
}

export default App;
