export const BREAKPOINTS = {
  phone: 600,
  tablet: 950,
  laptop: 1024,
};

export const QUERIES = {
  phoneAndSmaller: `(max-width: ${BREAKPOINTS.phone / 16}rem)`,
  tabletAndSmaller: `(max-width: ${BREAKPOINTS.tablet / 16}rem)`,
  laptopAndSmaller: `(max-width: ${BREAKPOINTS.laptop / 16}rem)`,
};

export const NUM_NOWSHOWING_MOVIES = 14;
export const NUM_RECOMMENDATIONS = 28;

export const MAX_SLIDER_ITEMS = 7;
export const MAX_SEARCH_RESULTS_PER_PAGE = 20;
export const MAX_NOWSHOWING_PER_PAGE = 25;
export const MAX_RECOMMENDATIONS_PER_PAGE = 30;

export const TRUE = 1;
export const FALSE = 0;

export const SEARCH_CATEGORIES = [
  { value: 'All', name: 'All Categories' },
  { value: 'Name', name: 'Movie Title' },
  { value: 'Director', name: 'Director' },
  { value: 'Genre', name: 'Genre' },
  { value: 'Description', name: 'Description' },
];

export const SEARCH_SORT_BY = [
  { value: 'Popularity', name: 'By Popularity' },
  { value: 'Alphabetical', name: 'By Alphabetical' },
  { value: 'Release', name: 'By Release Year' },
];

export const SEARCH_SORT_ORDER = ['Descending', 'Ascending'];
