import { Box, Button, Card, CardMedia, Grid, Paper } from '@mui/material';
import styled, { css } from 'styled-components';

import { QUERIES } from '../../util/constants';

import theme from '../../theme';

export const Flex = styled.div`
  display: flex;
`;

export const FlexWrap = styled(Flex)`
  flex-wrap: wrap;
`;

export const CenteredContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const VerticallyCenteredContainer = styled(Flex)`
  align-items: center;
`;

export const HorizontallyCenteredContainer = styled(Flex)`
  justify-content: center;
`;

export const CenteredForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Row = styled(Flex)`
  flex-direction: row;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const ColumnFlexEnd = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const RowFlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CardOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;

  :focus-within {
    opacity: 1;
    transition: opacity 200ms ease-in;
    transition-delay: 100ms;
  }
`;

export const CardContainer = styled(Card)`
  ${(props) => css`
    width: ${props.width}px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border: 2px solid white;
  `}

  && {
    box-shadow: none;
  }

  :hover {
    z-index: 3;
    isolation: isolate;
    transform: scale(1.2, 1.1);
    transition: all 200ms ease-in;
    transition-delay: 100ms;
  }

  &:hover ${CardOverlay} {
    opacity: 1;
    transition: opacity 200ms ease-in;
    transition-delay: 110ms;
  }
`;

export const Page = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BackgroundPaper = styled(Paper)`
  ${(props) => css`
    display: flex;
    flex-direction: column;
    place-content: center;
    margin: ${(props) => props.margin || '0'};
    padding: ${(props) => props.padding || '15px'};
    && {
      background: ${props.palette.primary.lightest};
    }
    height: 100%;
  `}
`;

export const LandingPage = styled(Row)`
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;

  @media (max-width: 750px) {
    flex-direction: column;
    position: absolute;
  }
`;

export const HeroContainer = styled.div`
  --sidePadding: 90px;
  --minContentSize: 300px;
  display: grid;
  grid-template-columns: minmax(var(--sidePadding), 1fr) repeat(2, minmax(var(--minContentSize), 500px)) minmax(
      var(--sidePadding),
      1fr
    );
  grid-template-rows: 1fr 400px 1fr;
  align-items: center;
  min-height: calc(100vh - 50px);
  width: 100%;
  gap: 0 40px;
  position: relative;

  @media ${QUERIES.tabletAndSmaller} {
    --sidePadding: 20px;
    --minContentSize: auto;
    gap: 0 10px;
  }

  @media ${QUERIES.phoneAndSmaller} {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const BackdropContainer = styled.div`
  position: absolute;
  z-index: -1;
`;
