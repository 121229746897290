import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import Leaderboard from '../components/Quiz/Leaderboard';
import { BackgroundPaper, Page } from '../components/styledComponents/Containers';

import { makeApiReq } from '../api/internal_api';
import theme from '../theme';

const AnsBox = styled(Box)`
  ${(props) => css`
    && {
      padding: 10px;
      margin: 10px 10px 20px;
      border-width: 2px;
      border-style: solid;
      border-radius: 3px;
      border-color: ${props.isCorrect !== null
        ? props.isCorrect
          ? props.palette.success.main
          : props.palette.error.main
        : props.palette.secondary.main};
      background-color: ${props.isCorrect !== null
        ? props.isCorrect
          ? props.palette.success.lightest
          : props.palette.error.lightest
        : props.palette.secondary.lightest};
    }
  `}
`;

const MyRankBox = styled(AnsBox)`
  ${(props) => css`
    && {
      border-color: ${props.palette.primary.main};
      background-color: ${props.palette.primary.lightest};
      margin: 10px 30px 0px;
      padding: 0;
    }
  `}
`;

/** Given user submits a quiz answer,
 * display whether quiz answer was correct or not +
 * leaderboard of top 5 overall users */
function Quiz({ userAns = false, quizPrompt, setShowLeaderboard, showLeaderboard }) {
  const [rankings, setRankings] = useState([]);
  const [currUserRanking, setCurrUserRanking] = useState(null);
  const [isUserInRankings, setIsUserInRankings] = useState(false);

  const fetchRankings = async () => {
    const token = window.localStorage.getItem('token');
    const resp = await makeApiReq('/quiz/leaderboard', null, 'GET', token);
    const data = await resp.json();
    setRankings(data.leaderboard);
    setCurrUserRanking(data.user);
    setIsUserInRankings(data.is_me_in_leaderboard);
  };

  useEffect(() => {
    if (userAns) fetchResult();
  }, []);

  useEffect(() => {
    fetchRankings();
  }, []);

  return (
    <Page>
      <BackgroundPaper palette={theme.palette} elevation={2} sx={{ width: '100%' }}>
        {rankings.length !== 0 && (
          <>
            <Leaderboard
              userList={rankings}
              currUserRanking={currUserRanking}
              setShowLeaderboard={setShowLeaderboard}
              showLeaderboard={showLeaderboard}
              isUserInRankings={isUserInRankings}
            />
          </>
        )}
      </BackgroundPaper>
    </Page>
  );
}

export default Quiz;
