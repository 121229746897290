import React from 'react';

import SimilarMovies from './SimilarMovies';
import YouMightAlsoLike from './YouMightAlsoLike';

/** Renders the recommendation components on the MoviePage */

const Recommendations = ({ m_id, reviews }) => (
  <>
    <SimilarMovies m_id={m_id} />
    <YouMightAlsoLike reviews={reviews} />
  </>
);

export default Recommendations;
