import React from 'react';

import { Typography } from '@mui/material';
import styled from 'styled-components';

import theme from '../../theme';
import { Flex } from '../styledComponents/Containers';
import ViewAllButton from './ViewAllButton';

export default function CarouselHeader({ children, route, showViewAllButton = true }) {
  return (
    <Header>
      <Typography variant='h1'>{children}</Typography>
      {showViewAllButton && <ViewAllButton route={route} />}
    </Header>
  );
}

const Header = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: 10px var(--page-padding);
  margin-top: 10px;
  background-color: ${theme.palette.primary.lightest};
  border-radius: 3px;
`;
