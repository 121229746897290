import React from 'react';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { IconButton, Typography } from '@mui/material';

import { VerticallyCenteredContainer } from '../../../styledComponents/Containers';

/**
  Component responsible for adding a user to the banned list
 */
const AddToBannedList = ({ author, handleBan }) => {
  return (
    <VerticallyCenteredContainer>
      <IconButton size='small' color='primary' onClick={handleBan}>
        <DoNotDisturbIcon />
      </IconButton>
      <Typography variant='caption'>Don't want to see {author}'s reviews? Add them to your banned list</Typography>
    </VerticallyCenteredContainer>
  );
};

export default AddToBannedList;
