import React, { useState } from 'react';

import { Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';

import theme from '../../theme';
import UserModal from '../UserModal';

export default function LeaderboardBar({ user, idx, barHeights }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <BarContainer>
      <Tooltip title={`view ${user.username}'s watchlist`} placement='top'>
        <BarButton onClick={handleOpen} aria-label={`view ${user.username}'s watchlist`}>
          <BarDetailsContainer>
            {user.username}
            <Typography variant='caption'>Score: {user.score}</Typography>
          </BarDetailsContainer>
          <Bar height={barHeights[idx]} userRank={user.rank}></Bar>
        </BarButton>
      </Tooltip>
      <UserModal username={user.username} open={open} handleClose={handleClose} />
    </BarContainer>
  );
}

const BarContainer = styled.figure`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BarDetailsContainer = styled.figcaption`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

const Bar = styled.div`
  width: 90%;
  height: ${(props) => props.height}px;
  background-color: ${theme.palette.primary.light};
  position: relative;
`;

const BarButton = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
