import React from 'react';

import { useParams } from 'react-router-dom';

import MoviePage from '../../../pages/MoviePage';

/**
 * checks if m_id are numbers before rendering MoviePage
 * ... our db m_ids start from 11 and sometimes skips numbers which makes it hard to validate ids properly
 * TODO: change m_id in db to start from 0 and be consecutive numbers in a range
 */
export default function ValidateMovie() {
  let params = useParams();
  let m_id = params.m_id.match(/\d+/);
  if (!m_id) {
    return <div>not found</div>;
  }
  return <MoviePage m_id={params.m_id} />;
}
