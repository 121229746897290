import styled from 'styled-components';

import { Flex } from '../styledComponents/Containers';

export const Container = styled(Flex)`
  flex-direction: row;
  align-items: center;
`;

export const TextContainer = styled.div`
  margin-right: 10px;
`;
