import React from 'react';

import { Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { BREAKPOINTS, QUERIES } from '../util/constants';

import popcornLogo from '../assets/popcorn-logo.svg';

import { StyledButton } from '../components/styledComponents/Buttons';
import { BackdropContainer, HeroContainer, Image, Page } from '../components/styledComponents/Containers';
import { Spacer } from '../components/styledComponents/Divider';

const HeroTextContainer = styled.div`
  grid-column-start: 2;
  grid-row-start: 2;
  display: flex;
  flex-direction: column;
`;

const HeroImageContainer = styled.div`
  grid-column-end: -2;
  grid-row-start: 2;
`;
const HeroImage = styled(Image)`
  width: 100%;
  height: 90vh;
  object-fit: contain;

  @media ${QUERIES.phoneAndSmaller} {
    height: 100vw;
  }
`;

const RectangleBackdrop = styled(BackdropContainer)(({ theme }) => ({
  gridColumn: '1 / -1',
  gridRow: '2 / -2',
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.lighter,
}));

export default function Landing({}) {
  const navigate = useNavigate();

  const isPhone = useMediaQuery(`${BREAKPOINTS.phone}`);

  return (
    <Page>
      <HeroContainer>
        <HeroTextContainer>
          <Typography variant='heroTitle' gutterBottom={true}>
            Find and review movies and so much more!!
          </Typography>
          <Typography variant='heroSubtitle'>
            A better, more seamless website for all your movie insight and engagement needs.
          </Typography>
          <Spacer height='20px' />
          <StyledButton
            variant='contained'
            onClick={() => {
              navigate('/register');
            }}
          >
            Create an account
          </StyledButton>
        </HeroTextContainer>
        <HeroImageContainer>
          <HeroImage
            alt='popcorn bucket with Moviefinder logo'
            src={require('../assets/popcorn-logo.png')}
            srcSet={popcornLogo}
          />
        </HeroImageContainer>
        <RectangleBackdrop />
      </HeroContainer>
      <Spacer />
    </Page>
  );
}
