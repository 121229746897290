//TODO: refactor cases to use typescript interface !!
export const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE_WISHLIST':
      return { ...state, watchlist: action.payload };
    case 'INITIALISE_NOWSHOWING':
      return { ...state, nowShowing: action.payload };
    case 'INITIALISE_RECOMMENDATIONS':
      return { ...state, recommendations: action.payload };

    case 'addToWatchlist':
      action.payload.is_in_watchlist = true;
      const existingMovieIndex = state.watchlist.findIndex((movie) => movie.m_id === action.payload.m_id);
      if (existingMovieIndex !== -1) {
        state.watchlist[existingMovieIndex].is_in_watchlist = true;
      } else {
        // Movie is not in watchlist yet, add it
        state.watchlist = [...state.watchlist, action.payload];
      }

      state.nowShowing = state.nowShowing.map((movie) => getSelectedMovie(movie, action));
      state.recommendations = state.recommendations.map((movie) => getSelectedMovie(movie, action));
      return { ...state };

    case 'removeFromWatchlist':
      action.payload.is_in_watchlist = false;
      state.watchlist = state.watchlist.map((movie) => getSelectedMovie(movie, action));
      state.nowShowing = state.nowShowing.map((movie) => getSelectedMovie(movie, action));
      state.recommendations = state.recommendations.map((movie) => getSelectedMovie(movie, action));
      return { ...state };

    default:
      throw new Error();
  }
};

const getSelectedMovie = (movie, action) => {
  return movie.m_id === action.payload.m_id ? action.payload : movie;
};

export const wishlistDispatchFn = (dispatch, data) => {
  dispatch({
    type: 'INITIALISE_WISHLIST',
    payload: data,
  });
};

export const nowshowingDispatchFn = (dispatch, data) => {
  dispatch({
    type: 'INITIALISE_NOWSHOWING',
    payload: data,
  });
};

export const recommendationsDispatchFn = (dispatch, data) => {
  dispatch({
    type: 'INITIALISE_RECOMMENDATIONS',
    payload: data,
  });
};
