import { Button } from '@mui/material';
import styled, { css } from 'styled-components';

import theme from '../../theme';

export const StyledButton = styled(Button)`
  ${(props) => css`
    width: ${props.width}px;

    && {
      margin: ${props.margin};
    }
  `}
`;

export const TransparentFullHeightButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
`;

export const PrimaryLightButton = styled(Button)`
  && {
    color: white;
    background-color: ${theme.palette.primary.light};
  }
`;
