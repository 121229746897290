/** to easily call backend endpoints without repeating much code */
export async function makeApiReq(path, payload, method, token = null) {
  const header = {
    Accept: 'application/json',
  };

  if (payload) {
    header['Content-Type'] = 'application/json';
  }

  if (token) {
    header.token = token;
  }

  const options = {
    method,
    headers: header,
  };

  if (payload) {
    options.body = JSON.stringify(payload);
  }

  return await fetch('/api' + path, options);
}
