import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';

import { makeApiReq } from '../../../api/internal_api';
import BookmarkButton from '../../Carousel/BookmarkButton';
import ViewSnackbar from '../../Snackbars/LinkSnackbar';
import { VerticallyCenteredContainer } from '../../styledComponents/Containers';

/* This component is responsible for adding a movie to a watchlist (or displaying that)
   it is already part of the watchlist */
const AddToWatchList = ({
  state,
  dispatch,
  m_id,
  statePayload,
  is_in_watchlist,
  setIsInWatchlist,
  isBookmarkIcon = false,
}) => {
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleWatchlist = async (method) => {
    const payload = {
      m_id: parseInt(m_id),
    };
    const token = window.localStorage.getItem('token');

    switch (method) {
      case 'DELETE':
        const resp = await makeApiReq('/watchlist', payload, method, token);
        if (resp.status === 200) {
          if (location.pathname === '/home') {
            dispatch({
              type: 'removeFromWatchlist',
              payload: statePayload,
            });
          } else {
            setIsInWatchlist(!is_in_watchlist);
          }
          enqueueSnackbar('Successfully removed from', {
            variant: 'success',
            content: (key, message) => (
              <ViewSnackbar message={message} path='/viewAll/watchlist' linkName='your watchlist' />
            ),
          });
        } else {
          enqueueSnackbar('Something went wrong, please try again later', { variant: 'error' });
        }
        break;

      case 'POST':
        const response = await makeApiReq('/watchlist', payload, method, token);
        if (response.status === 200) {
          if (location.pathname === '/home') {
            dispatch({
              type: 'addToWatchlist',
              payload: statePayload,
            });
          } else {
            setIsInWatchlist(!is_in_watchlist);
          }
          enqueueSnackbar('Successfully added to', {
            variant: 'success',
            content: (key, message) => (
              <ViewSnackbar message={message} path='/viewAll/watchlist' linkName='your watchlist' />
            ),
          });
        } else {
          enqueueSnackbar('Something went wrong, please try again later', { variant: 'error' });
        }
        break;
      default:
        break;
    }
  };

  return is_in_watchlist ? (
    <VerticallyCenteredContainer style={{ minWidth: '100%' }}>
      {isBookmarkIcon ? (
        <BookmarkButton type='remove' handleClick={() => handleWatchlist('DELETE')} />
      ) : (
        <Button
          style={{ minWidth: '100%', borderWidth: '2px' }}
          variant='outlined'
          size='small'
          onClick={() => handleWatchlist('DELETE')}
          color='error'
          startIcon={<RemoveIcon />}
        >
          Remove from watchlist
        </Button>
      )}
    </VerticallyCenteredContainer>
  ) : (
    <VerticallyCenteredContainer style={{ minWidth: '100%' }}>
      {isBookmarkIcon ? (
        <BookmarkButton type='add' handleClick={() => handleWatchlist('POST')} />
      ) : (
        <Button
          style={{ minWidth: '100%', borderWidth: '2px' }}
          variant='outlined'
          size='small'
          onClick={() => handleWatchlist('POST')}
          color='primary'
          startIcon={<AddIcon />}
        >
          Add to watchlist
        </Button>
      )}
    </VerticallyCenteredContainer>
  );
};

export default AddToWatchList;
