import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { MAX_SEARCH_RESULTS_PER_PAGE } from '../util/constants';

import NoResultsText from '../components/NoResultsText';
import PaginationButtons from '../components/PaginationButtons';
import SearchGrid from '../components/Search/SearchGrid';
import { CenteredContainer } from '../components/styledComponents/Containers';

import { makeApiReq } from '../api/internal_api';

export const PaginationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 80vh;
  padding: 0 20px 0 20px;
`;

/** displays search results from a query and category using pagination */
function SearchResults() {
  const [searchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(1);
  const [resultOffset, setResultOffset] = useState(0);
  const [results, setResults] = useState([]);

  const [loading, setLoading] = useState(true);
  const [totalResults, setTotalResults] = useState(0);

  const query = searchParams.get('query');
  const category = searchParams.get('category');
  const sortBy = searchParams.get('sortBy');

  const totalPages = Math.ceil(totalResults / MAX_SEARCH_RESULTS_PER_PAGE);

  const fetchSearchResults = async () => {
    const token = window.localStorage.getItem('token');
    const apiPath =
      '/movie/search?' +
      new URLSearchParams({
        query,
        category,
        sortBy,
        start: (currPage - 1) * MAX_SEARCH_RESULTS_PER_PAGE,
        end: currPage * MAX_SEARCH_RESULTS_PER_PAGE,
      });
    const resp = await makeApiReq(apiPath, null, 'GET', token);
    const data = await resp.json();

    setResults(data.movies);
    setTotalResults(data.total_count);
    setLoading(false);
  };

  useEffect(() => {
    fetchSearchResults();
  }, [resultOffset]);

  const handlePaginationChange = (page) => {
    setCurrPage(page);
    setResultOffset(page === 1 ? 0 : page * MAX_SEARCH_RESULTS_PER_PAGE);
    setLoading(true);
  };

  return (
    <PaginationWrapper>
      {loading ? (
        <CenteredContainer>
          <CircularProgress size={60} />
        </CenteredContainer>
      ) : totalResults ? (
        <>
          <Typography variant='caption'>
            Found {totalResults} {totalResults > 1 ? 'results' : 'result'}
          </Typography>
          <Typography variant='caption'>
            Page {currPage} of {totalPages}
          </Typography>

          <SearchGrid moviesData={results} />
          <PaginationButtons
            currPage={currPage}
            totalPages={totalPages}
            handlePaginationChange={handlePaginationChange}
          />
        </>
      ) : (
        <NoResultsText mainText={'No results found'} subtitleText={'Check your spelling or try a different category'} />
      )}
    </PaginationWrapper>
  );
}

export default SearchResults;
