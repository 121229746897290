import React from 'react';

import { Box } from '@mui/material';
import styled from 'styled-components';

import { makeApiReq } from '../../../../api/internal_api';
import { Flex } from '../../../styledComponents/Containers';
import MyComment from './MyComment';
import NotMyComment from './NotMyComment';

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 10px 10px 5px 15px;
  width: 100%;
`;

export const NameAndRating = styled(Flex)`
  flex-direction: row;
`;

/** 
   This component is responsible for rendering the existing reviews for a movie 
*/

const Comment = ({ reviews, setReviews, setMovieRating, m_id }) => {
  /** update movie rating + hide banned user */
  const handleBan = async (bannedUser) => {
    const token = window.localStorage.getItem('token');
    const payload = {
      username: bannedUser,
      m_id: parseInt(m_id),
    };
    const resp = await makeApiReq('/bannedlist/add', payload, 'POST', token);
    if (resp.status === 200) {
      const data = await resp.json();
      setReviews([...reviews.filter((review) => review.author !== bannedUser)]);
      setMovieRating(data.overall_rating);

      /* need to force refresh of page to update recommendation ratings
        commenting out since reload disrupts ux and realtime recommendation ratings not essential
        rating of curr viewed movie still updates accordingly */
      // window.location.reload();
    }
  };

  return reviews.map((review, idx) => {
    const isMyReview = localStorage.getItem('username') === review.author;
    return isMyReview ? (
      <Box sx={{ m: 1 }} key={`comment${idx}`}>
        <MyComment
          author={review.author}
          rating={review.rating}
          comment={review.comment}
          timestamp={review.timestamp}
          r_id={review.r_id}
          reviews={reviews}
          setReviews={setReviews}
          setMovieRating={setMovieRating}
        />
      </Box>
    ) : (
      <Box sx={{ m: 1 }} key={`comment${idx}`}>
        <NotMyComment
          author={review.author}
          rating={review.rating}
          comment={review.comment}
          timestamp={review.timestamp}
          handleBan={() => handleBan(review.author)}
        />
      </Box>
    );
  });
};

export default Comment;
