import React, { useEffect, useState } from 'react';

import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { QUERIES, SEARCH_SORT_BY, SEARCH_SORT_ORDER } from '../../util/constants';

import { handleWhiteSpace } from '../AuthForm/SubmitButton';
import { VerticalDivider } from '../styledComponents/Divider';
import SearchInput from './SeachInput';
import SearchMenu from './SearchMenu';
import SortMenu from './SortMenu';

/** search bar containing category dropdown menu
 * (All, Name, Director, Genre, Description) and input field */
function SearchBar({
  searchQuery,
  setSearchQuery,
  searchCategory,
  setSearchCategory,
  clearAnchorEl,
  setClearAnchorEl,
  isSearchBarOpen,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [searchSortBy, setSearchSortBy] = useState(SEARCH_SORT_BY[0].value);
  const [searchSortOrder, setSearchSortOrder] = useState(SEARCH_SORT_ORDER[0]);

  useEffect(() => {
    const sortByParams = searchParams.get('sortBy');
    if (sortByParams) {
      setSearchSortBy(sortByParams);
    }
  }, []);

  const handleChangeSearchCategory = (event) => {
    setSearchCategory(event.target.value);
  };

  const handleSearchSortBy = (event) => {
    setSearchSortBy(event.target.value);
  };

  // only navigate to search page if query isn't all whitespace
  const handleSubmitSearch = () => {
    // if query is wrapped in quotes, give exact search query
    // otherwise remove excess whitespace
    const query = searchQuery.match(/^".*"$/) ? searchQuery : handleWhiteSpace(searchQuery);

    if (query) {
      navigate({
        pathname: '/search',
        search: createSearchParams({
          query: query,
          category: searchCategory,
          sortBy: searchSortBy,
        }).toString(),
      });
    }
  };

  // when user is on search page, automatically search on category change
  useEffect(() => {
    if (location.pathname === '/search' && searchQuery) {
      handleSubmitSearch();
    }
  }, [searchCategory]);

  return (
    <SearchBarWrapper isSearchBarOpen={isSearchBarOpen}>
      <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        handleSubmit={handleSubmitSearch}
        clearAnchorEl={clearAnchorEl}
        setClearAnchorEl={setClearAnchorEl}
      />
      <SearchMenu
        searchCategory={searchCategory}
        handleChangeSearchCategory={handleChangeSearchCategory}
        searchSortBy={searchSortBy}
        handleSearchSortBy={handleSearchSortBy}
      />
    </SearchBarWrapper>
  );
}

export default SearchBar;

const SearchBarWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  margin: 0 50px;
  background-color: white;
  color: black;
  border-radius: 4px;

  @media ${QUERIES.tabletAndSmaller} {
    margin: 0 5px;
    display: ${(props) => (props.isSearchBarOpen ? 'inherit' : 'none')};
  }
`;
