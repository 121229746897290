import React from 'react';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ViewAllButton({ route }) {
  const navigate = useNavigate();

  return (
    <Button size='small' variant='text' onClick={() => navigate(`/viewAll/${route}`)}>
      View All
    </Button>
  );
}
