import React, { useEffect, useState } from 'react';

import { useLocation, useSearchParams } from 'react-router-dom';

import { FALSE, MAX_NOWSHOWING_PER_PAGE, MAX_RECOMMENDATIONS_PER_PAGE } from '../util/constants';

import CarouselHeader from '../components/Carousel/CarouselHeader';
import SearchGrid from '../components/Search/SearchGrid';

import { fetchNowshowing, fetchRecommendations, fetchWatchlist } from '../api/fetch';

//TODO: when refactor to ts, use interface or enum ...
const VIEWALL_PAGE = {
  watchlist: 'watchlist',
  recommendations: 'recommendations',
  nowshowing: 'nowshowing',
};

export default function ViewAll() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [watchlist, setWatchlist] = useState(null);
  const [recommendations, setRecommendations] = useState(null);
  const [nowshowing, setNowshowing] = useState(null);

  const username = searchParams.get('username');
  const myUsername = window.localStorage.getItem('username');
  let { pageType, heading } = getPageTypeAndHeading(location, username, myUsername);

  useEffect(() => {
    if (pageType === VIEWALL_PAGE.watchlist) {
      fetchWatchlist(setWatchlist, username, null, null);
    } else if (pageType === VIEWALL_PAGE.recommendations) {
      fetchRecommendations(setRecommendations, MAX_RECOMMENDATIONS_PER_PAGE, FALSE, null, null);
    } else if (pageType === VIEWALL_PAGE.nowshowing) {
      fetchNowshowing(setNowshowing, MAX_NOWSHOWING_PER_PAGE, null);
    }
  }, []);

  return (
    <>
      <CarouselHeader showViewAllButton={false}>{heading}</CarouselHeader>
      {watchlist && <SearchGrid moviesData={watchlist} />}
      {recommendations && <SearchGrid moviesData={recommendations} />}
      {nowshowing && <SearchGrid moviesData={nowshowing} />}
    </>
  );
}

const getPageTypeAndHeading = (location, username, myUsername) => {
  let pageType;
  if (location.pathname === '/viewAll/watchlist') {
    pageType = VIEWALL_PAGE.watchlist;
  } else if (location.pathname === '/viewAll/recommendations') {
    pageType = VIEWALL_PAGE.recommendations;
  } else if (location.pathname === '/viewAll/nowshowing') {
    pageType = VIEWALL_PAGE.nowshowing;
  }

  let heading;
  if (VIEWALL_PAGE.watchlist && username && username !== myUsername) {
    heading = `${username}'s watchlist`;
  } else if (pageType === VIEWALL_PAGE.recommendations) {
    heading = 'Your recommendations';
  } else if (pageType === VIEWALL_PAGE.nowshowing) {
    heading = 'Nowshowing';
  } else {
    heading = 'Your watchlist';
  }

  return { pageType: pageType, heading: heading };
};
