import React, { useState } from 'react';

import { TextField, Typography } from '@mui/material';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { handleWhiteSpace } from '../AuthForm/SubmitButton';
import QuizSubmitButtons from './QuizSubmitButtons';

export const QuizInputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

/** input text field for quizzes that have only 1 option */
function QuizInput({ quizPrompt, setShowLeaderboard, setUserAns }) {
  const [quizInput, setQuizInput] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const navigate = useNavigate();

  const handleQuizInput = (input) => {
    const ans = handleWhiteSpace(input);
    setQuizInput(ans);
    setIsSubmitDisabled(!ans);
  };

  const handleEnterQuizInput = (e) => {
    if (e.key === 'Enter') handleSubmitQuiz();
  };

  const handleSubmitQuiz = (quizPrompt, quizInput) => {
    const ans = handleWhiteSpace(quizInput);
    if (ans) {
      navigate({
        pathname: '/quiz',
        search: createSearchParams({
          question: quizPrompt,
          ans: ans,
        }).toString(),
      });
    }
  };

  return (
    <div className='prevent-select'>
      <Typography variant='h7' component='div' sx={{ p: '0 10px 15px 15px' }}>
        {quizPrompt}
      </Typography>
      <QuizInputBox>
        <TextField
          sx={{ ml: 1, flex: 1, background: 'white' }}
          placeholder='Type your answer...'
          inputProps={{ 'aria-label': 'Quiz input' }}
          fullWidth={true}
          maxRows='1'
          variant='outlined'
          size='small'
          value={quizInput}
          color='secondary'
          onChange={(e) => handleQuizInput(e.target.value)}
          onKeyPress={(e) => handleEnterQuizInput(e)}
        />
        <QuizSubmitButtons
          setUserAns={setUserAns}
          quizInput={quizInput}
          quizPrompt={quizPrompt}
          isSubmitDisabled={isSubmitDisabled}
          setShowLeaderboard={setShowLeaderboard}
        />
      </QuizInputBox>
    </div>
  );
}

export default QuizInput;
