import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import styled, { css } from 'styled-components';

import theme from '../../theme';
import { handleWhiteSpace } from '../AuthForm/SubmitButton';
import QuizSubmitButtons from './QuizSubmitButtons';

/** https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 * using stackoverflow answer from ashleedawg & Laurens Holst
 * to randomly shuffle an array */
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * array.length);
    [array[i], array[j]] = [array[j], array[i]];
  }
}

//TODO: when refactor to ts, use interface or enum ...
const QUIZ_OPTION_BUTTON_TYPES = {
  notSubmitted: 'notSubmitted',
  submittedDefault: 'submittedDefault',
  submittedCorrect: 'submittedCorrect',
  submittedIncorrect: 'submittedIncorrect',
};

/** radio button group of provided quiz answers
 *  randomises order of answers */
function QuizOptions({ quizPrompt, options, setShowLeaderboard, setUserAns, quizResult, userAns }) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [quizInput, setQuizInput] = useState('');

  const handleChange = (e) => {
    setIsSubmitDisabled(false);
    setQuizInput(e.target.value);
  };

  const handleSubmitQuiz = (e, quizInput) => {
    e.preventDefault();
    const ans = handleWhiteSpace(quizInput);
    if (ans) {
      setUserAns(ans);
      setIsSubmitDisabled(true);
    }
  };

  let isSubmitted = quizResult.correctAns !== '';
  const handleSubmittedQuizIcons = (option) => {
    let isUserAnsIncorrect = option === userAns && userAns != quizResult.correctAns;
    if (!isSubmitted) return;

    if (quizResult.correctAns === option) {
      return <DoneIcon sx={{ color: theme.palette.success.main }} />;
    } else if (isUserAnsIncorrect) {
      return <CloseIcon sx={{ color: theme.palette.error.main }} />;
    } else {
      return <CloseIcon sx={{ color: theme.palette.grey.main }} />;
    }
  };

  const handleQuizOptionStyle = (option) => {
    let isCorrectAns = quizResult.correctAns === option;
    let isUserAnsIncorrect = option === userAns && userAns != quizResult.correctAns;
    if (isCorrectAns) {
      return QUIZ_OPTION_BUTTON_TYPES.submittedCorrect;
    } else if (isUserAnsIncorrect) {
      return QUIZ_OPTION_BUTTON_TYPES.submittedIncorrect;
    } else if (!isSubmitted) {
      return QUIZ_OPTION_BUTTON_TYPES.notSubmitted;
    }
    return QUIZ_OPTION_BUTTON_TYPES.submittedDefault;
  };

  return (
    <div className='prevent-select'>
      <Typography variant='h7' component='div' sx={{ pb: '15px' }}>
        {quizPrompt}
      </Typography>
      <form noValidate onSubmit={(e) => handleSubmitQuiz(e, quizInput)}>
        <RadioGroup aria-labelledby='quiz-answer-options' name='quiz-radio-buttons-group'>
          <QuizOptionGrid>
            {options.map((option, idx) => {
              return (
                <QuizOptionBox
                  className='prevent-select'
                  isSelected={quizInput === option}
                  $type={handleQuizOptionStyle(option)}
                  key={'quiz-option-' + idx}
                >
                  <FormControlLabel
                    value={option}
                    sx={{
                      width: '100%',
                      margin: 0,
                      '&& .Mui-disabled': { color: theme.palette.grey.dark },
                    }}
                    disabled={isSubmitted}
                    control={
                      <Radio
                        size='small'
                        color='secondary'
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '0.9rem',
                          },
                        }}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    }
                    label={<Typography variant='h7'>{option}</Typography>}
                  />
                  {handleSubmittedQuizIcons(option)}
                </QuizOptionBox>
              );
            })}
          </QuizOptionGrid>
        </RadioGroup>
        {quizResult.isCorrect && (
          <Typography variant='body2'>
            Correct! +{quizResult.answerScore}
            {quizResult.answerScore > 1 ? 'pts' : 'pt'}
          </Typography>
        )}
        {isSubmitted && !quizResult.isCorrect && <Typography variant='body2'>Better luck tomorrow</Typography>}
        <QuizSubmitButtons
          handleSubmitQuiz={handleSubmitQuiz}
          quizInput={quizInput}
          quizPrompt={quizPrompt}
          isSubmitDisabled={isSubmitDisabled}
          setShowLeaderboard={setShowLeaderboard}
        />
      </form>
    </div>
  );
}

export default QuizOptions;

const QuizOptionBox = styled.div`
  font-size: 0.9rem;
  padding: 2px;
  min-width: 100px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;

  ${(props) => {
    switch (props.$type) {
      case QUIZ_OPTION_BUTTON_TYPES.notSubmitted:
        return css`
          background-color: ${(props) =>
            props.isSelected ? theme.palette.secondary.lighter : theme.palette.secondary.lightest};
          border: ${(props) =>
            props.isSelected
              ? `2px solid ${theme.palette.secondary.main}`
              : `2px dashed ${theme.palette.secondary.main}`};
        `;
      case QUIZ_OPTION_BUTTON_TYPES.submittedCorrect:
        return css`
          background-color: ${theme.palette.success.lightest};
          border: 2px solid ${theme.palette.success.main};
        `;
      case QUIZ_OPTION_BUTTON_TYPES.submittedIncorrect:
        return css`
          background-color: ${theme.palette.error.lightest};
          border: 2px solid ${theme.palette.error.main};
        `;
      default:
        return css`
          background-color: ${theme.palette.primaryWhite.main};
          border: 2px dashed ${theme.palette.grey.main};
        `;
    }
  }}
`;

const QuizOptionGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  padding-bottom: 15px;
`;
