import React from 'react';

import { SEARCH_SORT_BY } from '../../util/constants';

import DropdownMenu from '../DropdownMenu';

export default function SortMenu({ searchSortBy, handleChange }) {
  return (
    <DropdownMenu
      menuValue={searchSortBy}
      handleChange={handleChange}
      id='search-sort-menu'
      menuItems={SEARCH_SORT_BY}
    />
  );
}
