import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { makeApiReq } from '../../api/internal_api';
import PasswordInput from './PasswordInput';
import SubmitButton, { handleWhiteSpace } from './SubmitButton';

/** Modal will appear where users can enter the email address
 * which they registered with such that a verification code can be sent
 * through email to allow them to change their pw */
const ConfirmCodeModal = ({ open, handleClose, codeResp, setCodeResp, pwChangeEmail, setAuth, isCodeReq }) => {
  const [userCode, setUserCode] = useState('');
  const [isMatch, setIsMatch] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [errorText, setErrorText] = useState('');

  const handleSendCode = async () => {
    if (isCodeReq) {
      const payload = {
        email: pwChangeEmail,
      };
      const resp = await makeApiReq('/auth/code', payload, 'POST', null);
      if (resp.status === 200) {
        const data = await resp.json();
        setCodeResp(data);
      }
    }
  };

  useEffect(() => {
    handleSendCode();
  }, [isCodeReq]);

  const handleEnterCode = (e) => {
    if (handleWhiteSpace(userCode) && e.key === 'Enter') {
      checkCode();
    }
  };

  const checkCode = () => {
    const code = handleWhiteSpace(userCode);
    if (codeResp && code && code === codeResp.code) {
      setIsMatch(true);
      closeSnackbar();
      setErrorText('');
      enqueueSnackbar('Code accepted!', { variant: 'success' });
    } else {
      setErrorText("The code you've entered is incorrect. Please try again.");
      if (errorText !== '') enqueueSnackbar(errorText, { variant: 'error' });
    }
  };

  const handleChangePw = async () => {
    const payload = {
      u_id: codeResp.u_id,
      password: password,
    };
    const resp = await makeApiReq('/auth/changepassword', payload, 'PUT', null);
    if (resp.status === 200) {
      handleCloseModal();
      closeSnackbar();
      enqueueSnackbar('Password successfully changed!', { variant: 'success' });
    } else {
      enqueueSnackbar('Something went wrong. Please try again', {
        variant: 'error',
      });
    }
  };

  const handleEnterPassword = (e) => {
    if (e.key === 'Enter') {
      if (e.target.value) handleChangePw();
    }
  };

  const handleCloseModal = () => {
    handleClose();
    setIsMatch(false);
    setErrorText('');
    setPassword('');
    closeSnackbar();
  };

  const handleActionButtons = (handleDisabled, handleSubmit) => {
    return (
      <DialogActions>
        <Button color='error' onClick={handleCloseModal}>
          Cancel
        </Button>
        <SubmitButton
          buttonName='Confirm'
          variant='text'
          size='small'
          disabled={handleDisabled}
          handleSubmit={handleSubmit}
        />
      </DialogActions>
    );
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box style={{ padding: '40px 30px' }}>
        {!isMatch ? (
          <>
            <DialogTitle id='modal-modal-title'>Verify code to reset your password</DialogTitle>
            <DialogContent>
              <DialogContentText id='modal-modal-description'>
                If you have an account under {pwChangeEmail}, you should receive an email with a verification code
                shortly.
              </DialogContentText>
              <DialogContentText id='modal-modal-description' variant='caption'>
                Please also check your spam box for this email.
              </DialogContentText>
              <TextField
                autoFocus
                required
                fullWidth
                margin='dense'
                id='code'
                label='Code'
                type='text'
                error={errorText.length !== 0}
                helperText={errorText}
                onChange={(e) => setUserCode(e.target.value)}
                onKeyPress={handleEnterCode}
              />
            </DialogContent>
            {handleActionButtons(!handleWhiteSpace(userCode), checkCode)}
          </>
        ) : (
          <>
            <DialogTitle id='modal-modal-title'>Change your password</DialogTitle>
            <PasswordInput
              label='New Password'
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              setPassword={setPassword}
              onKeyPress={handleEnterPassword}
            />
            {handleActionButtons(!password, handleChangePw)}
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default ConfirmCodeModal;
