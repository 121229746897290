import React from 'react';

import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { makeApiReq } from '../../api/internal_api';
import { Column, Row } from '../styledComponents/Containers';

const CinemaTimesContainer = styled.div`
  padding: 5px;
  border: 0.5px solid black;
  margin-bottom: 5px;
`;

const CinemaTimesButtonsContainer = styled(Row)`
  flex-wrap: wrap;
`;

/**
 Given a cinema, date and movie, render the times available for that day
 */
const CinemaTimes = ({ cinema, date }) => {
  const { m_id } = useParams();
  const { address, name, times, c_id } = cinema;

  const getMovieTimeLink = async (time) => {
    const path = `/movie/book?c_id=${c_id}&m_id=${m_id}&date=${date}&time=${time}`;
    const token = localStorage.getItem('token');
    const resp = await makeApiReq(path, null, 'GET', token);

    if (resp.status === 200) {
      const { url } = await resp.json();
      window.open(url, '_blank');
    }
  };

  return (
    <CinemaTimesContainer>
      <Column>
        <Typography variant='body1'>{name}</Typography>
        <Typography variant='caption'>Address: {address}</Typography>
        <Typography variant='caption'>Session Times:</Typography>
        <ButtonGroup variant='contained' aria-label='outlined primary button group' disableElevation>
          <CinemaTimesButtonsContainer>
            {times ? (
              times.map((time, idx) => (
                <div key={idx}>
                  <Button size='small' onClick={() => getMovieTimeLink(time)}>
                    {time}
                  </Button>
                  <Box sx={{ m: 0.5 }} />
                </div>
              ))
            ) : (
              <Typography variant='caption'>No times available today</Typography>
            )}
          </CinemaTimesButtonsContainer>
        </ButtonGroup>
      </Column>
    </CinemaTimesContainer>
  );
};
export default CinemaTimes;
