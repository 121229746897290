import React, { useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { QUERIES } from '../../util/constants';

import { makeApiReq } from '../../api/internal_api';
import { StyledButton } from '../styledComponents/Buttons';
import { HorizontallyCenteredContainer, Row, VerticallyCenteredContainer } from '../styledComponents/Containers';
import { StyledTextField } from '../styledComponents/Input';
import CinemaTimes from './CinemaTimes';

/**
   Component responsible for handling user input for a date, postcode and then finding the cinemas
   where that movie is being shwon on the specific date. Then, for each cinema, calls CinemaTimes
   to find the specific movie times for that cinema. 
 */
const MovieTimes = () => {
  const { m_id } = useParams();

  const [date, setDate] = useState(new Date());
  const [postcode, setPostcode] = useState('');
  const [movieTimesData, setMovieTimesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const now = new Date();
  const nowPlus6Days = moment(now).add(6, 'd').toDate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errorText, setErrorText] = useState('');

  const formatDate = (date) => moment(date).format('YYYY-MM-DD');

  const handleDateChange = (newValue) => {
    const formattedDate = formatDate(newValue);
    setDate(formattedDate);
  };

  // Only allow digits, maximum length 4, allow backspaces to empty
  const handlePostcodeChange = (newValue) => {
    if ((!/[a-zA-Z]/.test(newValue) || !newValue) && newValue.length < 5) {
      setPostcode(newValue);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setHasSubmitted(true);
    const formattedDate = formatDate(date);
    const token = localStorage.getItem('token');
    const path = `/movie/schedule?m_id=${m_id}&date=${formattedDate}&postcode=${postcode}`;

    const resp = await makeApiReq(path, null, 'GET', token);
    const data = await resp.json();
    if (resp.status === 200) {
      setMovieTimesData(data);
      setErrorText('');
    } else {
      setMovieTimesData([]);
      setErrorText(data.errors.postcode);
    }
    setIsLoading(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <h3>Watch it in cinemas now:</h3>
      <TimeInputContainer>
        <VerticallyCenteredContainer>
          <DesktopDatePicker
            label='Pick a date'
            inputFormat='dd/MM/yyyy'
            value={date}
            minDate={now}
            maxDate={nowPlus6Days}
            onChange={handleDateChange}
            slots={{ textField: (params) => <TextField sx={{ width: 160 }} {...params} /> }}
          />
          <Box sx={{ m: 0.5 }} />
          <StyledTextField
            value={postcode}
            onChange={(e) => handlePostcodeChange(e.target.value)}
            width={150}
            label='Postcode'
            size='small'
            error={errorText.length > 0}
            helperText={errorText}
          />
        </VerticallyCenteredContainer>
        <Box sx={{ m: 1 }} />
        <StyledButton onClick={handleSubmit} disabled={postcode.length !== 4 || !date}>
          Find movie times near me
        </StyledButton>
      </TimeInputContainer>
      <Box sx={{ m: 1 }} />
      {isLoading ? (
        <HorizontallyCenteredContainer style={{ padding: '40px' }}>
          <CircularProgress size={40} />
        </HorizontallyCenteredContainer>
      ) : movieTimesData && movieTimesData.length > 0 ? (
        movieTimesData.map((cinema, idx) => <CinemaTimes key={idx} cinema={cinema} date={formatDate(date)} />)
      ) : (
        hasSubmitted &&
        errorText.length === 0 && (
          <Typography variant='body2'>No cinemas are showing the selected movie today</Typography>
        )
      )}
    </LocalizationProvider>
  );
};

export default MovieTimes;

const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${QUERIES.phoneAndSmaller} {
    flex-direction: column;
  }
`;
