import React, { useState } from 'react';

import { Box } from '@mui/material';

import { makeApiReq } from '../../../api/internal_api';
import { StyledButton } from '../../styledComponents/Buttons';
import { HorizontallyCenteredContainer } from '../../styledComponents/Containers';
import GiveItARating from './GiveItARating';
import WriteAReview from './WriteAReview';

/* This component is responsible for writing a review in the Movie Page. It
   contains a Star Rating component, Text Box for writing the review, and a
   submit button to handle submitting the review */

const Review = ({ m_id, reviews, setReviews, setMovieRating }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const canSubmit = !!rating;

  const hasSubmittedAReview = reviews && reviews.length > 0 && reviews[0].author === localStorage.getItem('username');

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const payload = {
        m_id: parseInt(m_id),
        rating,
        comment,
      };
      const resp = await makeApiReq('/reviewers', payload, 'POST', token);
      const data = await resp.json();

      if (resp.status === 200) {
        const newReview = {
          author: localStorage.getItem('username'),
          rating,
          comment,
          r_id: data.r_id,
        };
        setReviews([newReview, ...reviews]);
        setRating(null);
        setComment('');
        setMovieRating(data.overall_rating);
      } else {
        alert(data.Error);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    !hasSubmittedAReview && (
      <>
        <GiveItARating rating={rating} setRating={setRating} />
        <WriteAReview comment={comment} setComment={setComment} />
        <Box sx={{ m: 0.5 }}></Box>
        <HorizontallyCenteredContainer>
          <StyledButton variant='contained' size='small' disabled={!canSubmit} onClick={() => handleSubmit()}>
            Submit Review
          </StyledButton>
        </HorizontallyCenteredContainer>
      </>
    )
  );
};

export default Review;
