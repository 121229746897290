import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { makeApiReq } from '../../api/internal_api';
import SearchGrid from '../Search/SearchGrid';
import { HorizontallyCenteredContainer } from '../styledComponents/Containers';
import NoRecommendationsText from './NoRecommendationsText';

/** display recommendations based on user's reviews
 * if no reviews yet, component is hidden
 * updates on review state
 */
const YouMightAlsoLike = ({ reviews }) => {
  const [forYouMovies, setForYouMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const NUM_FOR_YOU_MOVIES = 6;

  const getForYouMovies = async () => {
    const token = window.localStorage.getItem('token');
    const apiPath =
      `/recommendation?` +
      new URLSearchParams({
        n: NUM_FOR_YOU_MOVIES,
      });

    const resp = await makeApiReq(apiPath, null, 'GET', token);
    const data = await resp.json();
    setForYouMovies(data.movies);
    setIsLoading(false);
  };

  useEffect(() => {
    getForYouMovies();
  }, [reviews]);
  return (
    <Box>
      <Typography variant='contained'>You might also like</Typography>
      <Box sx={{ m: 1.5 }} />
      {!isLoading ? (
        forYouMovies.length > 0 ? (
          <SearchGrid moviesData={forYouMovies} $padding='1px' />
        ) : (
          <NoRecommendationsText />
        )
      ) : (
        <HorizontallyCenteredContainer style={{ padding: '100px' }}>
          <CircularProgress size={40} />
        </HorizontallyCenteredContainer>
      )}
    </Box>
  );
};

export default YouMightAlsoLike;
