import React from 'react';

import { Box, CardActionArea, CardContent, CardMedia, Rating, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { EmptyCardMedia } from './Carousel/SliderCard';
import { Column } from './styledComponents/Containers';

/**
 Generic component for rendering a MovieCard, given movie properties
*/

const MovieCard = ({ movie }) => {
  const navigate = useNavigate();

  return (
    <Column>
      <CardActionArea onClick={() => navigate(`/movie/${movie.m_id}`)}>
        {movie.image ? (
          <CardMedia component='img' image={movie.image} />
        ) : (
          <EmptyCardMedia>
            <Typography variant='h7' component='div'>
              No image available
            </Typography>
          </EmptyCardMedia>
        )}
      </CardActionArea>
      <CardContent sx={{ p: '10px 10px 0' }}>
        <Typography variant='h7' component='div'>
          {movie.title}
        </Typography>
        <Box sx={{ m: 0.5 }} />
        {movie.rating ? (
          <Rating value={movie.rating} size='small' precision={0.5} readOnly />
        ) : (
          <Typography variant='caption'>No rating data</Typography>
        )}
      </CardContent>
    </Column>
  );
};

export default MovieCard;
