import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { makeApiReq } from '../../api/internal_api';

const ColumnList = styled(List)`
  & {
    overflow: scroll;
    max-height: 150px;
    max-width: 100%;
    display: flex;
    flex-flow: wrap;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

const ColumnListItem = styled(ListItem)`
${(props) => css`
  max-width: ${props.listlen > 3 ? (props.listlen > 9 ? '20%' : '30%') : '100%'};
`}
  
}
`;

/** Displays all users in the banned list */
function BannedList({ bannedList }) {
  const navigate = useNavigate();

  const handleBan = (user) => {
    const token = window.localStorage.getItem('token');
    const payload = {
      username: user,
    };
    makeApiReq('/bannedlist/remove', payload, 'DELETE', token);

    // force refresh of home page to display new ratings
    navigate('/home');
  };

  return (
    <ColumnList dense>
      {bannedList.map((user, idx) => {
        return (
          <ColumnListItem key={'banned-' + idx} listlen={12}>
            <Tooltip arrow title='Unban user?'>
              <IconButton aria-label='unban-user' onClick={() => handleBan(user.username)}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <ListItemText primary={user.username} />
          </ColumnListItem>
        );
      })}
    </ColumnList>
  );
}

export default BannedList;
