import React, { useContext, useEffect } from 'react';

import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { StoreContext } from '../util/store';

import ValidateMovie from '../components/MovieDetails/Review/ValidateMovie';
import Navbar from '../components/Navbar/Navbar';

import Home from './Home';
import Landing from './Landing';
import Login from './Login';
import MoviePage from './MoviePage';
import Quiz from './Quiz';
import Register from './Register';
import SearchResults from './SearchResults';
import ViewAll from './ViewAll';

/** renders a menubar + page routes depending on path
 * - (if unknown path, render landing page) */
function Navigation() {
  const context = useContext(StoreContext);
  const [auth, setAuth] = context.auth;
  const location = useLocation();

  // navigate to home if user already logged in and hasn't logged out yet
  useEffect(() => {
    setAuth(!!window.localStorage.getItem('token'));
  }, [location.pathname]);

  // https://reactrouter.com/docs/en/v6/examples/auth
  // protects routes wrapped in RequireAuth
  function RequireAuth() {
    if (!window.localStorage.getItem('token')) {
      return <Navigate to='/login' />;
    }
    return <Outlet />;
  }

  return (
    <>
      <Navbar auth={auth} />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route element={<RequireAuth />}>
          <Route path='/home' element={<Home />} />
          <Route path='/movie/:m_id' element={<ValidateMovie />} />
          <Route path='/movie/*' element={<div>not found</div>} />
          <Route path='/search' element={<SearchResults />} />
          <Route path='/viewAll/watchlist' element={<ViewAll />} />
          <Route path='/viewAll/recommendations' element={<ViewAll />} />
          <Route path='/viewAll/nowshowing' element={<ViewAll />} />
          <Route path='*' element={<Navigate replace to='/home' />} />
        </Route>
      </Routes>
    </>
  );
}

export default Navigation;
