import React from 'react';

import LocalMoviesIcon from '@mui/icons-material/LocalMovies';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const LogoButton = styled(Button)`
  ${(props) => css`
    && {
      background-color: inherit;
      color: ${props.textcolor};
      margin: 0;
      padding: 0;
    }
  `}
`;

function Logo({
  auth = false,
  textcolor = 'white',
  setSearchCategory = null,
  setSearchQuery = null,
  setClearAnchorEl = null,
}) {
  const navigate = useNavigate();

  // make logo clickable to /home if user is logged in
  const handleClick = () => {
    if (auth) {
      navigate('/home');
      // reset search bar
      setSearchCategory('All');
      setSearchQuery('');
      setClearAnchorEl(false);
    } else {
      navigate('/');
    }
  };

  return (
    <>
      <LogoButton
        variant='contained'
        disableElevation={true}
        disableRipple={true}
        onClick={handleClick}
        textcolor={textcolor}
      >
        <LocalMoviesIcon />
        <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
          MovieFinder
        </Typography>
      </LogoButton>
    </>
  );
}

export default Logo;
