import React, { useEffect, useState } from 'react';

import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { QUERIES, SEARCH_CATEGORIES } from '../../util/constants';

import { makeApiReq } from '../../api/internal_api';
import theme from '../../theme';
import MobileSearch from '../Search/MobileSearch';
import SearchBar from '../Search/SearchBar';
import Logo from './Logo';

const StyledToolbar = styled(Toolbar)`
  && {
    min-height: 50px;
  }
`;

const SignUpButton = styled(Button)`
  @media ${QUERIES.phoneAndSmaller} {
    && {
      display: none;
    }
  }
`;

/** navbar containing clickable logo back to /home and logout button + search bar */
function Navbar({ auth }) {
  const navigate = useNavigate();
  const username = window.localStorage.getItem('username');
  const [searchParams] = useSearchParams();

  const [searchCategory, setSearchCategory] = useState(SEARCH_CATEGORIES[0].value);
  const [searchQuery, setSearchQuery] = useState('');
  const [clearAnchorEl, setClearAnchorEl] = useState(false);

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const queryParams = searchParams.get('query');
    const categoryParams = searchParams.get('category');
    if (queryParams) setSearchQuery(queryParams);
    if (categoryParams) setSearchCategory(categoryParams);
  }, []);

  // invalidate token from backend
  // and route to login page
  // {replace true} creates new history stack so users can't go back
  const handleLogout = async () => {
    const token = window.localStorage.getItem('token');
    const resp = await makeApiReq('/auth/logout', null, 'POST', token);

    if (resp.status === 200) {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('username');
      setSearchCategory('All');
      setSearchQuery('');
      navigate('/login', { replace: true });
      closeSnackbar();
    } else {
      enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
      });
    }
  };

  return !auth ? (
    <AppBar position='sticky'>
      <StyledToolbar>
        <Logo auth={auth} />
        <Box sx={{ flexGrow: 1, display: 'block' }} />
        <Button
          color='primaryWhite'
          variant='contained'
          sx={{ mr: 1 }}
          onClick={() => {
            navigate('/login');
          }}
        >
          Login
        </Button>
        <SignUpButton
          color='primaryWhite'
          variant='contained'
          onClick={() => {
            navigate('/register');
          }}
        >
          Create an account
        </SignUpButton>
      </StyledToolbar>
    </AppBar>
  ) : (
    <AppBar position='sticky' sx={{ mb: '10px' }}>
      <StyledToolbar>
        {isSearchBarOpen && (
          <Button
            variant='text'
            color='primaryWhite'
            onClick={() => {
              setIsSearchBarOpen(false);
            }}
          >
            back
          </Button>
        )}

        {!isSearchBarOpen && (
          <Logo
            auth={auth}
            setSearchCategory={setSearchCategory}
            setSearchQuery={setSearchQuery}
            setClearAnchorEl={setClearAnchorEl}
          />
        )}
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          clearAnchorEl={clearAnchorEl}
          setClearAnchorEl={setClearAnchorEl}
          isSearchBarOpen={isSearchBarOpen}
        />
        <MobileSearch
          handleClick={() => {
            setIsSearchBarOpen(true);
          }}
          isSearchBarOpen={isSearchBarOpen}
        />
        {!isSearchBarOpen && (
          <>
            <Typography variant='h6'>Hello {username}!</Typography>
            <Button
              style={{ marginLeft: '10px', color: 'white', backgroundColor: theme.palette.primary.light }}
              variant='contained'
              onClick={handleLogout}
            >
              Logout
            </Button>
          </>
        )}
      </StyledToolbar>
    </AppBar>
  );
}

export default Navbar;
