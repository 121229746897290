import React from 'react';

import { StyledButton } from '../styledComponents/Buttons';

/** replace all spaces (1 or more consecutive) to be a single space */
export const handleWhiteSpace = (toTrim) => {
  let input = toTrim.trim();
  return input.replace(/\s+/g, ' ');
};

/** FIXME: unnecessary overhead */
function SubmitButton({ buttonName, variant, size, disabled, width, margin, handleSubmit }) {
  return (
    <StyledButton
      variant={variant}
      type='submit'
      size={size}
      width={width}
      margin={margin}
      disabled={disabled}
      onClick={handleSubmit}
    >
      {buttonName}
    </StyledButton>
  );
}

export default SubmitButton;
