import React, { forwardRef } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Typography } from '@mui/material';
import { SnackbarContent } from 'notistack';
import styled from 'styled-components';

import theme from '../../theme';

const LinkSnackbar = forwardRef((props, ref) => {
  return (
    <SnackbarContentStyled ref={ref}>
      <CheckCircleIcon sx={{ fontSize: 20, color: 'white' }} />
      <Typography variant='subtitle2' sx={{ padding: '8px' }}>
        {props.message} &nbsp;
        <Link href={props.path}>{props.linkName}</Link>
      </Typography>
    </SnackbarContentStyled>
  );
});

export default LinkSnackbar;

const Link = styled.a`
  color: ${theme.palette.primary.main};
  background-color: white;
  padding: 2px;
  border-radius: 3px;
`;

const SnackbarContentStyled = styled(SnackbarContent)`
  background-color: ${theme.palette.success.main};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #fff;
  align-items: center;
  padding: 8px 24px 8px 20px;
  border-radius: 4px;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
`;
