import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { checkValidEmail } from './AuthForm';
import SubmitButton, { handleWhiteSpace } from './SubmitButton';

/** Modal will appear where users can enter the email address
 * which they registered with such that a verification code can be sent
 * through email to allow them to change their pw */
const VerifyEmailModal = ({ open, handleOpen, handleClose, pwChangeEmail, setPwChangeEmail, setIsCodeReq }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [emailErrorText, setEmailErrorText] = useState('');

  const handleCloseModal = () => {
    setEmailErrorText('');
    closeSnackbar(); // closes all error snackbars
    handleClose();
  };

  const handleEnterEmail = (e) => {
    if (e.key === 'Enter') {
      setEmailErrorText(checkValidEmail(e.target.value));
      if (!checkValidEmail(e.target.value)) {
        handleConfirmEmail();
      } else if (emailErrorText !== '') {
        enqueueSnackbar(emailErrorText, { variant: 'error' });
      }
    }
  };

  const handleConfirmEmail = async () => {
    const email = handleWhiteSpace(pwChangeEmail);
    closeSnackbar(); // closes all error snackbars
    if (email) {
      setIsCodeReq(true);
      handleOpen(false);
    }
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box style={{ padding: '40px 30px' }}>
        <DialogTitle id='modal-modal-title'>Forgot password?</DialogTitle>
        <DialogContent>
          <DialogContentText id='modal-modal-description'>
            Please enter the email address you used to register an account:
          </DialogContentText>
          <TextField
            autoFocus
            required
            fullWidth
            margin='dense'
            label='Email Address'
            type='email'
            error={emailErrorText.length !== 0}
            helperText={emailErrorText}
            onBlur={() => setEmailErrorText(checkValidEmail(pwChangeEmail))}
            onChange={(e) => setPwChangeEmail(e.target.value)}
            onKeyPress={handleEnterEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={handleCloseModal}>
            Cancel
          </Button>
          <SubmitButton
            buttonName='Confirm'
            variant='text'
            size='small'
            disabled={!handleWhiteSpace(pwChangeEmail) || !!emailErrorText}
            handleSubmit={handleConfirmEmail}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default VerifyEmailModal;
