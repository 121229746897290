import React, { useState } from 'react';

import { Box, List, Paper, Rating, Tooltip, Typography } from '@mui/material';

import theme from '../../../../theme';
import UserModal, { ClickableName } from '../../../UserModal';
import { BackgroundPaper } from '../../../styledComponents/Containers';
import AddToBannedList from './AddToBannedList';
import { Container, NameAndRating } from './Comment';
import { handleDate } from './helper';

/**
 This component is responsible for rendering another user's pre-existing comment (readonly), with the ability
 to add them to the banned list.
 */
const NotMyComment = ({ author, rating, comment, timestamp, handleBan }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const date = handleDate(timestamp);

  return (
    <BackgroundPaper palette={theme.palette} elevation={2}>
      <Container>
        <NameAndRating>
          <Tooltip title={`View ${author}'s profile`} arrow placement='right-start'>
            <ClickableName onClick={handleOpen} variant='boldSubtitle'>
              {author}
            </ClickableName>
          </Tooltip>
          <Box sx={{ m: 0.5 }}></Box>
          <Rating value={rating} size='small' precision={0.5} readOnly />
          <Box sx={{ m: 0.5 }}></Box>
          <Typography variant='caption'>{date}</Typography>
        </NameAndRating>
        <Box sx={{ m: 0.3 }}></Box>
        <Paper elevation={0} style={{ maxHeight: 100, overflow: 'auto', backgroundColor: 'inherit' }}>
          <List>
            <Typography variant='caption'>
              <pre
                style={{
                  fontFamily: 'inherit',
                  margin: 0,
                  whiteSpace: 'pre-wrap',
                }}
              >
                {comment}
              </pre>
            </Typography>
          </List>
        </Paper>
      </Container>
      <UserModal username={author} open={open} handleClose={handleClose} handleBan={handleBan} />
    </BackgroundPaper>
  );
};

export default NotMyComment;
