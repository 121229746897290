import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

import blob1 from '../../assets/blob1.svg';

import { makeApiReq } from '../../api/internal_api';
import { StyledButton } from '../styledComponents/Buttons';
import { BackdropContainer, CenteredContainer, CenteredForm, Image } from '../styledComponents/Containers';
import { Spacer, StyledDivider } from '../styledComponents/Divider';
import { StyledTextField } from '../styledComponents/Input';
import PasswordInput from './PasswordInput';
import SubmitButton, { handleWhiteSpace } from './SubmitButton';

/** checks whether given email addr is valid + returns string to set error text
 * - valid: return ''
 * - invalid: return 'Invalid email format' */
export const checkValidEmail = (emailAddress) => {
  return validator.isEmail(emailAddress) || !emailAddress ? '' : 'Invalid email format';
};

/**
 * a form with inputs depending on isRegister
 * - true: render username, email, password textfields
 * - false: render email, password textfields
 * @param {boolean} isRegister true if using on register page otherwise false
 */
function AuthForm({ isRegister, auth, setAuth }) {
  const [username, setUsername] = useState('');
  const [usernameErrorText, setUsernameErrorText] = useState('');

  const [emailAddress, setEmailAddress] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');

  const [confirmEmail, setConfirmEmail] = useState('');
  const [confirmationErr, setConfirmationErr] = useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [apiErrorText, setApiErrorText] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (!auth) {
      setUsername('');
      setEmailAddress('');
      setPassword('');
    }
  }, [auth]);

  const handleErrorSnackbar = () => {
    if (apiErrorText !== '') enqueueSnackbar(apiErrorText, { variant: 'error' });
  };

  const isAllInputEntered =
    (isRegister && username && password && emailAddress && confirmEmail) || (!isRegister && username && password);

  // usernames limited to 50 characters
  const checkValidUsername = (username) => {
    if (username.length > 50) {
      setUsername('');
      setUsernameErrorText('Usernames must be limited to 50 characters');
    } else {
      setUsernameErrorText('');
      setUsername(username);
    }
  };

  // removes whitespace from start/end + more than 1 space from username
  const checkWhitespace = () => {
    const user = handleWhiteSpace(username);
    setUsername(user);
  };

  const navigate = useNavigate();

  const handleApiResp = async (resp) => {
    if (resp.status === 200) {
      // success, navigate to home page
      const data = await resp.json();
      navigate('/home', { replace: true });
      setAuth(true);
      window.localStorage.setItem('token', data.token);
      window.localStorage.setItem('username', username);

      closeSnackbar(); // closes all error snackbars
    } else if (resp.status === 400) {
      // display error message
      const err = await resp.json();
      if (err.Error) {
        setApiErrorText(err.Error);
      } else {
        setApiErrorText('Please check your details and try again');
      }
      handleErrorSnackbar();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // error checks before sending api req
    if (emailErrorText || confirmationErr) {
      setApiErrorText('Please check your details and try again');
      handleErrorSnackbar();
      return;
    } else if (!isAllInputEntered) {
      setApiErrorText('Please enter all fields');
      handleErrorSnackbar();
      return;
    }

    const apiPath = isRegister ? '/auth/register' : '/auth/login';
    const payload = {
      username,
      password,
    };

    if (isRegister) payload.email = emailAddress;

    const resp = await makeApiReq(apiPath, payload, 'POST');
    handleApiResp(resp);
  };

  const handleEmailErr = () => {
    emailAddress && confirmEmail && confirmEmail !== emailAddress
      ? setConfirmationErr("Emails don't match. Please check carefully.")
      : setConfirmationErr(checkValidEmail(confirmEmail));

    setEmailErrorText(checkValidEmail(emailAddress));
  };

  return (
    <CenteredContainer>
      <h1>{isRegister ? 'Create an account' : 'Welcome back!'}</h1>
      <CenteredForm noValidate onSubmit={handleSubmit}>
        <Box sx={{ m: 0.5 }} />
        <StyledTextField
          width={300}
          id='outlined-username'
          label='Username'
          required={true}
          value={username}
          onChange={(e) => {
            checkValidUsername(e.target.value);
          }}
          onBlur={checkWhitespace}
          error={usernameErrorText.length !== 0}
          helperText={usernameErrorText}
        />
        {isRegister && (
          <>
            <Box sx={{ m: 0.5 }} />
            <StyledTextField
              width={300}
              id='email'
              label='Email Address'
              type='email'
              required={true}
              onChange={(e) => {
                setEmailAddress(e.target.value);
              }}
              onBlur={handleEmailErr}
              error={emailErrorText.length !== 0}
              helperText={emailErrorText}
            />
            <Box sx={{ m: 0.5 }} />
            <StyledTextField
              width={300}
              id='confirm-email'
              label='Confirm Email Address'
              type='email'
              required={true}
              onChange={(e) => {
                setConfirmEmail(e.target.value);
              }}
              onBlur={handleEmailErr}
              error={confirmationErr.length !== 0}
              helperText={confirmationErr}
            />
          </>
        )}
        <Box sx={{ m: 0.5 }} />
        <PasswordInput showPassword={showPassword} setShowPassword={setShowPassword} setPassword={setPassword} />
        <Box sx={{ m: 1 }} />
        <Typography variant='error' component='div'>
          {apiErrorText}
        </Typography>
        <Box sx={{ m: 1 }} />
        <SubmitButton
          buttonName={isRegister ? 'Register' : 'Login'}
          variant='contained'
          size='large'
          width={320}
          handleSubmit={handleSubmit}
        />
      </CenteredForm>
      <Spacer height='30px' />
      <StyledDivider flexItem variant='middle' />
      <Spacer height='30px' />
      <StyledButton
        variant='outlined'
        size='small'
        width={250}
        onClick={() => {
          isRegister ? navigate('/login') : navigate('/register');
        }}
      >
        {isRegister ? 'Already have an account?' : `Don't have an account?`}
      </StyledButton>
      <Spacer height='10px' />
      <BackdropContainer>
        <Image src={blob1} />
      </BackdropContainer>
    </CenteredContainer>
  );
}

export default AuthForm;
