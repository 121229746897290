import React from 'react';

import { Box, Pagination } from '@mui/material';
import styled from 'styled-components';

const PaginationButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: inherit;
`;

/** centers pagination buttons on page
 * @param currPage useState
 * @param handlePaginationChange should setCurrpage and setOffset, takes in page param
 */
function PaginationButtons({ currPage, totalPages, handlePaginationChange }) {
  return (
    <PaginationButtonContainer>
      <Pagination count={totalPages} page={currPage} onChange={(_, page) => handlePaginationChange(page)} />
    </PaginationButtonContainer>
  );
}
export default PaginationButtons;
