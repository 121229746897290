import Divider from '@mui/material/Divider';
import styled, { css } from 'styled-components';

import theme from '../../theme';

export const StyledDivider = styled(Divider)`
  ${(props) => css`
    margin: ${props.margin};
  `}
`;

export const Spacer = styled.div`
  min-height: ${(props) => (props.height ? props.height : '50px')};
`;

export const VerticalDivider = styled.div`
  width: 1px;
  height: ${(props) => (props.height ? props.height : '100%')};
  background-color: ${theme.palette.grey.light};
`;
