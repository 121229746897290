import React from 'react';

import styled from 'styled-components';

import SubmitButton, { handleWhiteSpace } from '../AuthForm/SubmitButton';
import { Spacer } from '../styledComponents/Divider';

const ButtonBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
`;

/** submit quiz button + leaderboard button to navigate to quiz results page */
function QuizSubmitButtons({
  showLeaderboardOnly = false,
  quizInput,
  isSubmitDisabled,
  setShowLeaderboard,
  handleSubmitQuiz,
}) {
  const handleViewLeaderboard = () => {
    setShowLeaderboard(true);
  };

  return (
    <ButtonBox>
      {!showLeaderboardOnly && (
        <>
          <SubmitButton
            buttonName='Submit'
            variant='contained'
            size='small'
            disabled={isSubmitDisabled}
            width={100}
            handleSubmit={(e) => handleSubmitQuiz(e, quizInput)}
          />
          <Spacer height='5px' />
        </>
      )}
      <SubmitButton
        buttonName='View Leaderboard'
        variant='outlined'
        size='small'
        disabled={false}
        handleSubmit={handleViewLeaderboard}
      />
    </ButtonBox>
  );
}

export default QuizSubmitButtons;
