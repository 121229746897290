import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';

import { QUERIES } from '../../util/constants';

import theme from '../../theme';

export default function MobileSearch({ handleClick, isSearchBarOpen }) {
  return (
    <SearchWrapper isSearchBarOpen={isSearchBarOpen}>
      <IconButton
        sx={{ padding: '2px' }}
        color='primaryWhite'
        type='button'
        aria-label='open search bar'
        onClick={handleClick}
      >
        <SearchIcon />
      </IconButton>
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div`
  display: none;
  background-color: ${theme.palette.primary.light};
  border-radius: 3px;
  margin-right: 20px;
  margin-left: auto;

  @media ${QUERIES.tabletAndSmaller} {
    display: ${(props) => (props.isSearchBarOpen ? 'none' : 'initial')};
  }
`;
