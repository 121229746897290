import React from 'react';

import { TextField } from '@mui/material';

/* This component contains the textbox for writing a review and is responsible 
   for maintaining the text of the review */

const WriteAReview = ({ comment, setComment }) => (
  <TextField
    id='outlined-multiline-flexible'
    multiline
    placeholder='Write your review'
    maxRows={4}
    value={comment}
    onChange={(e) => setComment(e.target.value)}
    fullWidth
    inputProps={{ style: { fontSize: 14 } }}
    props={{ style: { fontSize: 12 } }}
  />
);

export default WriteAReview;
