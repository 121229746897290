import React, { useState } from 'react';

import { Box, ListItem, ListItemIcon, Typography } from '@mui/material';
import styled from 'styled-components';

import theme from '../../theme';
import UserModal, { ClickableName } from '../UserModal';

const RankingIcon = styled(Box)`
  border: solid gray;
  border-radius: 5px;
  padding: 5px 10px;
`;
function UserRanking({ rank, username, isCurrUser, points, showDivider = true }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ListItem
        style={{
          backgroundColor: isCurrUser ? theme.palette.primary.lightest : '',
        }}
        divider={showDivider}
        secondaryAction={<Typography variant='h7'>{points} points</Typography>}
      >
        <ListItemIcon>
          <RankingIcon>
            <Typography variant='boldIcon'>{rank}</Typography>
          </RankingIcon>
        </ListItemIcon>

        {isCurrUser ? (
          <Typography variant='boldSubtitle'>Me</Typography>
        ) : (
          <>
            <ClickableName onClick={handleOpen} variant='boldSubtitle'>
              {username}
            </ClickableName>
            <UserModal username={username} open={open} handleClose={handleClose} />
          </>
        )}
      </ListItem>
    </>
  );
}

export default UserRanking;
