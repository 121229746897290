import React, { useState } from 'react';

import styled from 'styled-components';

import SliderCard from '../Carousel/SliderCard';
import { CardContainer } from '../styledComponents/Containers';

function SearchGrid({ moviesData, $padding }) {
  return (
    <CardGrid $padding={$padding}>
      {moviesData.map((movie, idx) => {
        const [isInWatchlist, setIsInWatchlist] = useState(movie.is_in_watchlist);
        return (
          <CardContainer key={`grid-item-${idx}`}>
            <SliderCard movie={movie} is_in_watchlist={isInWatchlist} setIsInWatchlist={setIsInWatchlist}></SliderCard>
          </CardContainer>
        );
      })}
    </CardGrid>
  );
}

export default SearchGrid;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  padding: ${(props) => (props.$padding ? props.$padding : `var(--page-padding)`)};
  grid-gap: 10px;
`;
