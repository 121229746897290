import React from 'react';

import theme from '../theme';
import { BackgroundPaper, HorizontallyCenteredContainer } from './styledComponents/Containers';

function LoadingBox() {
  return (
    <BackgroundPaper palette={theme.palette} elevation={2}>
      <HorizontallyCenteredContainer style={{ padding: '120px' }}></HorizontallyCenteredContainer>
    </BackgroundPaper>
  );
}

export default LoadingBox;
