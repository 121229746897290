import React, { useContext } from 'react';

import { Navigate } from 'react-router-dom';

import { StoreContext } from '../util/store';

import AuthForm from '../components/AuthForm/AuthForm';
import { CenteredContainer, LandingPage } from '../components/styledComponents/Containers';

/** Register page where users can create an account
 * using unique email, unique username (<50 char), password */
function Register() {
  const context = useContext(StoreContext);
  const [auth, setAuth] = context.auth;

  return (
    <>
      {auth && <Navigate to='/home' replace={true} />}
      <LandingPage>
        <CenteredContainer>
          <AuthForm isRegister={true} auth={auth} setAuth={setAuth} />
        </CenteredContainer>
      </LandingPage>
    </>
  );
}

export default Register;
