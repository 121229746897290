import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Box, CircularProgress, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';

import { StoreContext } from '../../util/store';

import { makeApiReq } from '../../api/internal_api';
import SearchGrid from '../Search/SearchGrid';
import { HorizontallyCenteredContainer } from '../styledComponents/Containers';
import { Container, TextContainer } from './Containers';

/** This component is responsible for rendering the similar movie recommendation
 * based on an input the user selects of the Director and Genre */
const SimilarMovies = ({ m_id }) => {
  const context = useContext(StoreContext);
  const [similarMovies, setSimilarMovies] = useState([]);
  const [genreSelected, setGenreSelected] = context.genreRec;
  const [directorSelected, setDirectorSelected] = context.directorRec;

  const getSimilarMovies = async () => {
    const token = window.localStorage.getItem('token');
    const apiPath =
      `/recommendationbymovie/${m_id}?` +
      new URLSearchParams({
        genre: genreSelected,
        director: directorSelected,
      });

    const resp = await makeApiReq(apiPath, null, 'GET', token);
    const data = await resp.json();
    setSimilarMovies(data.movies);
  };

  useEffect(() => {
    getSimilarMovies();
  }, []);

  const handleChange = (inputType) => {
    switch (inputType) {
      case 'director':
        // If we are unselecting director, we need to make sure that genre is ticked to true.
        if (genreSelected || !directorSelected) {
          setDirectorSelected(!directorSelected);
        }
        break;
      case 'genre':
        if (directorSelected || !genreSelected) {
          setGenreSelected(!genreSelected);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Container>
        <TextContainer>
          <Typography variant='contained'>Explore similar titles based on this movie's</Typography>
        </TextContainer>
        &nbsp;&nbsp;
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={directorSelected} onChange={() => handleChange('director')} />}
            label='Director'
          />
          <FormControlLabel
            control={<Switch checked={genreSelected} onChange={() => handleChange('genre')} />}
            label='Genre'
          />
        </FormGroup>
      </Container>
      <Box sx={{ m: 1.5 }} />
      <Box sx={{ minHeight: '510px' }}>
        {similarMovies.length > 0 ? (
          <SearchGrid moviesData={similarMovies} $padding='1px' />
        ) : (
          <HorizontallyCenteredContainer style={{ padding: '200px' }}>
            <CircularProgress size={40} />
          </HorizontallyCenteredContainer>
        )}
      </Box>
    </>
  );
};

export default SimilarMovies;
