import React from 'react';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Tooltip } from '@mui/material';
import styled from 'styled-components';

import theme from '../../theme';
import { TransparentFullHeightButton } from '../styledComponents/Buttons';

export default function BookmarkButton({ type = 'add', handleClick }) {
  return type === 'add' ? (
    <Tooltip title='Add to watchlist?' placement='top'>
      <ButtonLeeway onClick={handleClick} aria-label='add to watchlist'>
        <OutlinedBookmark sx={{ color: 'white' }} />
      </ButtonLeeway>
    </Tooltip>
  ) : (
    <Tooltip title='Remove from watchlist?' placement='top'>
      <ButtonLeeway onClick={handleClick} aria-label='remove from watchlist'>
        <OutlinedBookmark sx={{ color: theme.palette.secondary.main }} />
      </ButtonLeeway>
    </Tooltip>
  );
}

const OutlinedBookmark = styled(BookmarkIcon)`
  && {
    font-size: 2.2rem;
  }
  path {
    stroke: black;
    stroke-width: 1.5px;
    stroke-linejoin: round;
  }
`;

const ButtonLeeway = styled(TransparentFullHeightButton)`
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 0;
`;
