import React, { createContext, useState } from 'react';

export const StoreContext = createContext(null);

/** store context for auth
 * and other state that needed to be passed down multiple levels */
export function StoreProvider({ children }) {
  const [auth, setAuth] = useState(false);
  const [genreSelected, setGenreSelected] = useState(true);
  const [directorSelected, setDirectorSelected] = useState(true);

  const store = {
    auth: [auth, setAuth],
    genreRec: [genreSelected, setGenreSelected],
    directorRec: [directorSelected, setDirectorSelected],
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
}
