import React, { useContext, useState } from 'react';

import { Navigate } from 'react-router-dom';

import { StoreContext } from '../util/store';

import AuthForm from '../components/AuthForm/AuthForm';
import ConfirmCodeModal from '../components/AuthForm/ConfirmCodeModal';
import VerifyEmailModal from '../components/AuthForm/VerifyEmailModal';
import { StyledButton } from '../components/styledComponents/Buttons';
import { CenteredContainer, LandingPage } from '../components/styledComponents/Containers';

/** Login page where users can enter username + password to login */
const Login = () => {
  const context = useContext(StoreContext);
  const [auth, setAuth] = context.auth;

  const [pwChangeEmail, setPwChangeEmail] = useState('');
  const [codeResp, setCodeResp] = useState(null);
  const [openVerifyEmail, setOpenVerifyEmail] = useState(false);
  const [openVerifyCode, setOpenVerifyCode] = useState(false);
  const [isCodeReq, setIsCodeReq] = useState(false);

  const handleOpen = (isVerifyEmail) => {
    isVerifyEmail ? setOpenVerifyEmail(!openVerifyEmail) : setOpenVerifyCode(!openVerifyCode);
  };
  const handleClose = () => {
    setPwChangeEmail('');
    setOpenVerifyEmail(false);
    setOpenVerifyCode(false);
    setIsCodeReq(false);
  };

  return (
    <>
      {auth && <Navigate to='/home' replace={true} />}
      <LandingPage>
        <CenteredContainer>
          <AuthForm isRegister={false} auth={auth} setAuth={setAuth} />

          <VerifyEmailModal
            open={openVerifyEmail}
            handleOpen={handleOpen}
            handleClose={handleClose}
            pwChangeEmail={pwChangeEmail}
            setPwChangeEmail={setPwChangeEmail}
            setIsCodeReq={setIsCodeReq}
          />
          <ConfirmCodeModal
            open={openVerifyCode}
            handleClose={handleClose}
            pwChangeEmail={pwChangeEmail}
            codeResp={codeResp}
            setCodeResp={setCodeResp}
            setAuth={setAuth}
            isCodeReq={isCodeReq}
          />
          <StyledButton variant='outlined' size='small' width={250} onClick={() => handleOpen(true)}>
            Forgot password?
          </StyledButton>
        </CenteredContainer>
      </LandingPage>
    </>
  );
};

export default Login;
