import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';

import { makeApiReq } from '../api/internal_api';
import CarouselHeader from './Carousel/CarouselHeader';
import SnapCarousel from './Carousel/SnapCarousel';
import MovieCard from './MovieCard';
import AddToBannedList from './MovieDetails/Review/Comments/AddToBannedList';
import { HorizontallyCenteredContainer } from './styledComponents/Containers';

export const ClickableName = styled(Typography)`
  variant: boldSubtitle;
  cursor: pointer;
`;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '10px solid #9c0ea6',
  boxShadow: 24,
  p: 4,
  outline: 0,
};

const CloseButton = styled.div`
  float: right;
`;
/**
 Component responsible for grabbing the watchlist of a specific user and displaying it in a Modal
 */
const UserModal = ({ username, open, handleClose, handleBan }) => {
  const currUser = window.localStorage.getItem('username');
  const [usersWatchlist, setUsersWatchlist] = useState(null);
  const isCurrUser = currUser === username;

  const getWatchlist = async () => {
    const token = localStorage.getItem('token');
    const resp = await makeApiReq(`/watchlist?username=${username}`, null, 'GET', token);

    if (resp.status === 200) {
      const { watchlist } = await resp.json();
      const movies = watchlist;
      setUsersWatchlist([...movies.map((movie) => movie)]);
    }
  };

  useEffect(() => {
    getWatchlist();
  }, []);

  const handleRenderCards = (movie) => <MovieCard movie={movie}></MovieCard>;

  const Close = () => (
    <Tooltip title='Close' arrow>
      <CloseButton>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </CloseButton>
    </Tooltip>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close />
        <HorizontallyCenteredContainer>
          <h2>{isCurrUser ? 'You' : username}</h2>
          <Box sx={{ m: 1 }} />
        </HorizontallyCenteredContainer>
        {usersWatchlist && usersWatchlist.length > 0 ? (
          <>
            <CarouselHeader route={`watchlist?username=${username}`}>
              {isCurrUser ? 'Your' : username + "'s"} Watchlist
            </CarouselHeader>
            <SnapCarousel
              isOtherUser={true}
              username={username}
              movieData={usersWatchlist}
              handleRenderCards={handleRenderCards}
            />
          </>
        ) : (
          <>No movies in {username}'s watchlist</>
        )}
        {!isCurrUser && <AddToBannedList author={username} handleBan={handleBan} />}
      </Box>
    </Modal>
  );
};

export default UserModal;
