import React from 'react';

import { CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '../styledComponents/Buttons';
import SliderCard from './SliderCard';

/** slider card for nowShowing movies
 * (adds a times+locations button to the SliderCard component) */
function NowShowingSliderCard({ movie, is_in_watchlist, state, dispatch, isInvisible }) {
  const navigate = useNavigate();

  return (
    <div style={{ visibility: isInvisible ? 'hidden' : 'visible' }}>
      <SliderCard movie={movie} is_in_watchlist={is_in_watchlist} state={state} dispatch={dispatch} />
      <CardActions sx={{ pl: 0, pr: 0 }}>
        <StyledButton
          sx={{ padding: '5px' }}
          variant='contained'
          size='small'
          width={350}
          onClick={() => navigate(`/movie/${movie.m_id}`)}
        >
          Times and Locations
        </StyledButton>
      </CardActions>
    </div>
  );
}

export default NowShowingSliderCard;
