import React, { useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Tooltip } from '@mui/material';
import styled from 'styled-components';

function SearchInput({ searchQuery, setSearchQuery, handleSubmit, clearAnchorEl, setClearAnchorEl }) {
  const anchorRef = useRef();

  const handleSearchQuery = (event) => {
    setSearchQuery(event.target.value);
    setClearAnchorEl(true);
  };

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleClear = () => {
    setSearchQuery('');
    setClearAnchorEl(false);
  };

  return (
    <SearchInputWrapper>
      <Tooltip title='Search'>
        <IconButton sx={{ padding: '2px' }} type='submit' aria-label='search' onClick={handleSubmit}>
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <StyledSearchInput
        anchorEl={anchorRef}
        type='text'
        aria-label='Search'
        placeholder='Search...'
        maxRows='1'
        value={searchQuery}
        onChange={handleSearchQuery}
        onKeyPress={handleEnterSearch}
      />
      {clearAnchorEl && (
        <Tooltip title='Clear'>
          <IconButton ref={anchorRef} type='button' aria-label='clear search' onClick={handleClear}>
            <CloseIcon sx={{ fontSize: '15px' }} />
          </IconButton>
        </Tooltip>
      )}
    </SearchInputWrapper>
  );
}

export default SearchInput;

const SearchInputWrapper = styled.div`
  flex: 1 1 auto;
`;

const StyledSearchInput = styled.input`
  margin-left: 1px;
  font-size: 0.9rem;
  border: none;
  width: calc(100% - 70px);
  :focus {
    outline: none;
  }
`;
