import React from 'react';

import { SEARCH_CATEGORIES } from '../../util/constants';

import DropdownMenu from '../DropdownMenu';
import { VerticalDivider } from '../styledComponents/Divider';
import SortMenu from './SortMenu';

function SearchMenu({ searchCategory, handleChangeSearchCategory, handleSearchSortBy, searchSortBy }) {
  return (
    <>
      <VerticalDivider height='20px' />
      <DropdownMenu
        menuValue={searchCategory}
        handleChange={handleChangeSearchCategory}
        id='select-search-category'
        menuItems={SEARCH_CATEGORIES}
      />
      <VerticalDivider height='20px' />
      <SortMenu searchSortBy={searchSortBy} handleChange={handleSearchSortBy} />
    </>
  );
}

export default SearchMenu;
