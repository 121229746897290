import React from 'react';

import { Typography } from '@mui/material';
import Rating from '@mui/material/Rating';
import styled from 'styled-components';

import { VerticallyCenteredContainer } from '../../styledComponents/Containers';

const Container = styled.div`
  margin-bottom: 5px;
`;

/* This component renders the Star Rating component and is responsible for 
   maintaining the state of the current rating */

const GiveItARating = ({ rating, setRating }) => (
  <Container>
    <VerticallyCenteredContainer>
      <Typography variant='body2'>Give it a rating </Typography>&nbsp;
      <Rating
        name='simple-controlled'
        value={rating}
        precision={0.5}
        onChange={(_, newValue) => {
          setRating(newValue);
        }}
      />
      &nbsp;&nbsp;
    </VerticallyCenteredContainer>
  </Container>
);

export default GiveItARating;
