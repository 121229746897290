import React, { useEffect, useState } from 'react';

import { Box, CircularProgress, Rating, Typography } from '@mui/material';
import styled from 'styled-components';

import { QUERIES } from '../util/constants';

import AddToWatchlist from '../components/MovieDetails/Review/AddToWatchlist';
import Comment from '../components/MovieDetails/Review/Comments/Comment';
import Review from '../components/MovieDetails/Review/Review';
import MovieTimes from '../components/MovieTimes/MovieTimes';
import Recommendations from '../components/Recommendations/Recommendations';
import { BackgroundPaper, CenteredContainer, Column, Flex, Row } from '../components/styledComponents/Containers';
import { StyledDivider } from '../components/styledComponents/Divider';

import { makeApiReq } from '../api/internal_api';
import theme from '../theme';

const PageContainer = styled(Flex)`
  flex-direction: row;
`;
const LeftSide = styled.div`
  padding: 15px 0 0 15px;
  margin-right: 15px;
  width: 50%;
`;
const RightSide = styled.div`
  width: 50%;
  padding: 15px 15px 0 15px;
`;

const Title = styled.h2`
  margin: 20px 0 20px 0;
`;

const Heading = styled.h3`
  margin: 0;
`;

const ImageContainer = styled.img`
  margin: 0 5px 0 10px;
`;

const RatingContainer = styled.div`
  margin-bottom: 10px;
`;

/** Movie details page displaying all details about the selected movie,
 * all reviews associated with the movie, ability to add/edit/delete own review,
 * similar movie recommendations + 'you may also like' movie recommendations */
const MoviePage = ({ m_id }) => {
  const [movieData, setMovieData] = useState(null);
  const [isInWatchlist, setIsInWatchlist] = useState(null);
  const [reviews, setReviews] = useState(null);
  const [movieRating, setMovieRating] = useState(null);

  const getMovieData = async () => {
    try {
      const token = localStorage.getItem('token');
      const resp = await makeApiReq(`/movie/details/${m_id}`, null, 'GET', token);
      const data = await resp.json();
      setMovieData(data);
      setIsInWatchlist(data.is_in_watchlist);
      setReviews(data.reviews);
      setMovieRating(data.rating);
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  useEffect(() => {
    getMovieData();
  }, []);

  const cast = [];
  movieData && movieData.cast.map((actor) => cast.push(actor));

  const genres = [];
  movieData && movieData.genre.map((genre) => genres.push(genre));

  return movieData ? (
    <PageGrid isNowshowing={movieData.is_nowshowing}>
      <MovieDetailsContainer>
        <DetailsContainer>
          <Column>
            <BackgroundPaper palette={theme.palette} padding={'2px 20px 20px'} elevation={2}>
              <Title>{movieData.title}</Title>
              <RatingContainer>
                {movieRating ? (
                  <Rating value={movieRating} size='medium' precision={0.5} readOnly />
                ) : (
                  <Typography variant='subtitle1'>No rating data available</Typography>
                )}
              </RatingContainer>
              <Typography variant='caption' gutterBottom>
                <div>
                  <Typography variant='boldSubtitle'>Length: </Typography>
                  {movieData.duration ? movieData.duration + ' minutes' : 'TBD'}
                </div>
                <div>
                  <Typography variant='boldSubtitle'>Year Released: </Typography>
                  {movieData.release_year ? movieData.release_year : 'Unknown'}
                </div>
                {genres && (
                  <div>
                    <Typography variant='boldSubtitle'>Genre: </Typography>
                    {genres.join(', ')}
                  </div>
                )}
                <Box sx={{ m: 1 }} />
                <div>
                  <Typography variant='boldSubtitle'>Overview: </Typography>
                  {movieData.description}
                </div>
                <Box sx={{ m: 1 }} />
                <AddToWatchlist is_in_watchlist={isInWatchlist} setIsInWatchlist={setIsInWatchlist} m_id={m_id} />
                <Box sx={{ m: 1 }} />
                {movieData.director && (
                  <div>
                    <Typography variant='boldSubtitle'>
                      {movieData.director.length > 1 ? 'Directors: ' : 'Director: '}
                    </Typography>
                    {movieData.director.join(', ')}
                  </div>
                )}
                {cast && (
                  <div>
                    <Typography variant='boldSubtitle'>Cast: </Typography>
                    {cast.join(', ')}
                  </div>
                )}
              </Typography>
            </BackgroundPaper>
          </Column>
          <div>{movieData.image && <ImageContainer src={movieData.image}></ImageContainer>}</div>
        </DetailsContainer>
        <Box sx={{ m: 2 }}>
          <StyledDivider />
        </Box>
      </MovieDetailsContainer>
      <ReviewContainer>
        <Heading>Reviews</Heading>
        <Box sx={{ m: 1 }} />
        <Review m_id={m_id} reviews={reviews} setReviews={setReviews} setMovieRating={setMovieRating} />
        <Box sx={{ m: 1 }}></Box>
        <Column>
          {reviews && reviews.length > 0 && (
            <Comment reviews={reviews} setReviews={setReviews} setMovieRating={setMovieRating} m_id={m_id} />
          )}
        </Column>
      </ReviewContainer>

      {movieData.is_nowshowing && (
        <NowshowingContainer>
          <MovieTimes />
          <Box sx={{ m: 2 }}>
            <StyledDivider />
          </Box>
        </NowshowingContainer>
      )}

      <RecommendationsContainer>
        <Recommendations m_id={m_id} reviews={reviews} />
      </RecommendationsContainer>
    </PageGrid>
  ) : (
    <CenteredContainer>
      <CircularProgress size={60} />
    </CenteredContainer>
  );
};

export default MoviePage;

const PageGrid = styled.div`
  display: grid;
  grid-template-columns: 5fr 3fr;
  grid-template-rows: min-content;
  gap: 10px;
  margin: 10px;
  grid-template-areas: ${(props) =>
    props.isNowshowing
      ? `'movie-details nowshowing'
    'movie-details recommendations'
    'reviews recommendations'
    'reviews recommendations'`
      : `'movie-details recommendations'
    'reviews recommendations'
    'reviews recommendations'`};

  @media ${QUERIES.tabletAndSmaller} {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-template-areas:
      'movie-details'
      'nowshowing'
      'reviews'
      'recommendations';
  }
`;

const MovieDetailsContainer = styled.div`
  grid-area: movie-details;
`;

const ReviewContainer = styled.div`
  grid-area: reviews;
  padding: 20px;
`;

const NowshowingContainer = styled.div`
  grid-area: nowshowing;
`;

const RecommendationsContainer = styled.div`
  grid-area: recommendations;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${QUERIES.phoneAndSmaller} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
