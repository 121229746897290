import React from 'react';

import { Typography } from '@mui/material';
import styled from 'styled-components';

import { FlexWrap } from '../styledComponents/Containers';

export default function Genres({ genres }) {
  return (
    <FlexWrap>
      {genres &&
        genres.map((genre, idx) => {
          return (
            <GenreWrapper key={`genre-${idx}`}>
              <Typography sx={{ lineHeight: 0 }} variant='caption'>
                {genre}
              </Typography>
            </GenreWrapper>
          );
        })}
    </FlexWrap>
  );
}

const GenreWrapper = styled.div`
  line-height: 0.9rem;
  :not(:last-of-type)::after {
    content: '\\2022';
    padding: 0 1px;
  }
`;
