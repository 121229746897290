import React from 'react';

import styled from 'styled-components';

import { CardContainer, EndColumn } from '../styledComponents/Containers';
import CarouselHeader from './CarouselHeader';
import ViewAllButton from './ViewAllButton';

/**
 * Carousel slider displaying movie cards
 * @param handleRenderCards function to render card component, pass in data prop
 * */
function SnapCarousel({ movieData, handleRenderCards }) {
  return (
    <SnapCarouselContainer>
      {movieData.map((data, idx) => {
        return <CardContainer key={'slider-card' + idx}>{handleRenderCards(data)}</CardContainer>;
      })}
    </SnapCarouselContainer>
  );
}

export default SnapCarousel;

export const CarouselContainer = styled.div`
  --_spacing: 8px;
  --_spacing-large: calc(var(--_spacing) * 4);

  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(170px, 240px);
  gap: var(--_spacing);

  overflow-x: auto;
  overflow-y: hidden;
  overscroll-behavior-inline: contain;
  padding: 16px var(--_spacing);
`;

export const SnapCarouselContainer = styled(CarouselContainer)`
  padding-left: var(--_spacing-large);
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--_spacing-large);
  & > * {
    scroll-snap-align: start;
  }
`;
