import { createTheme } from '@mui/material/styles';

/** customised MUI theme */
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#9c0ea6',
      light: '#e487eb',
      lighter: '#fbdbff',
      lightest: '#fef5ff',
    },
    secondary: {
      main: '#f6be00',
      light: '#f5cf4e',
      lighter: '#fff0a3',
      lightest: '#fffaea',
    },
    primaryWhite: {
      main: '#fff',
      contrastText: '#9c0ea6',
    },
    grey: {
      main: '#717171',
      dark: '#575757',
      light: '#c2c2c2',
      lightest: '#f8f8f8',
    },
    success: {
      main: '#43a047',
      lightest: '#f4fff2',
    },
    error: {
      main: '#e3022f',
      light: '#ffe1e1',
      lightest: '#ffe8ea',
    },
  },
  typography: {
    error: {
      color: '#d32f2f',
      fontSize: 12,
    },
    h1: {
      fontSize: '1.35rem',
      fontWeight: '550',
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: '500',
    },
    h7: {
      fontSize: '0.9rem',
    },
    heroTitle: {
      fontWeight: '600',
      fontSize: '4.5rem',
      lineHeight: '4.5rem',
    },
    heroSubtitle: {
      fontSize: '1.5rem',
    },
    boldSubtitle: {
      fontWeight: 'bold',
      fontSize: 14,
    },
    boldIcon: {
      fontWeight: 'bold',
      fontSize: 18,
    },
    caption: {
      fontSize: '0.7rem',
    },
    cardCaption: {
      fontSize: '0.75rem',
      fontWeight: '550',
    },
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
});

export default theme;
