import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, List, Paper, Rating, TextField, Tooltip, Typography } from '@mui/material';

import { makeApiReq } from '../../../../api/internal_api';
import theme from '../../../../theme';
import { StyledButton } from '../../../styledComponents/Buttons';
import { BackgroundPaper, RowFlexEnd } from '../../../styledComponents/Containers';
import { Container, NameAndRating } from './Comment';
import { handleDate } from './helper';

/**
 This component is responsible for rendering the logged-in user's pre-existing comment, with
 the ability to edit and delete it.
 */
const MyComment = ({ author, rating, comment, timestamp, r_id, reviews, setReviews, setMovieRating }) => {
  const [currentComment, setCurrentComment] = useState(comment);
  const [currentRating, setCurrentRating] = useState(rating);
  const [isEditing, setIsEditing] = useState(false);
  const [isValidEdit, setIsValidEdit] = useState(!!currentComment && !!currentRating);
  const date = handleDate(timestamp);

  useEffect(() => {
    setIsValidEdit(!!currentComment && !!currentRating);
  }, [currentComment, currentRating]);

  const handleSaveChanges = async () => {
    const token = localStorage.getItem('token');
    const newReview = {
      author: localStorage.getItem('username'),
      rating: currentRating,
      comment: currentComment,
      r_id,
    };
    const resp = await makeApiReq('/reviewers', newReview, 'PUT', token);
    if (resp.status === 200) {
      const data = await resp.json();
      setReviews([newReview, ...reviews.slice(1)]);
      setIsEditing(false);
      setMovieRating(data.overall_rating);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setCurrentComment(comment);
    setCurrentRating(rating);
  };

  const handleDeleteComment = async () => {
    const token = localStorage.getItem('token');
    const resp = await makeApiReq('/reviewers', { r_id }, 'DELETE', token);
    if (resp.status === 200) {
      const data = await resp.json();
      setReviews([...reviews.filter((review) => review.r_id !== r_id)]);
      setMovieRating(data.overall_rating);
    }
  };

  return (
    <BackgroundPaper palette={theme.palette} elevation={2}>
      <Container>
        <NameAndRating>
          <Typography variant='boldSubtitle'>Me</Typography>
          <Box sx={{ m: 0.5 }}></Box>
          <Rating
            value={currentRating}
            size='small'
            precision={0.5}
            readOnly={!isEditing}
            onChange={(_, newValue) => setCurrentRating(newValue)}
          />
          <Box sx={{ m: 0.5 }}></Box>
          <Typography variant='caption'>{date}</Typography>
        </NameAndRating>
        <Box sx={{ m: 0.3 }}></Box>
        {isEditing ? (
          <TextField
            multiline
            fullWidth
            maxRows={4}
            value={currentComment}
            onChange={(e) => setCurrentComment(e.target.value)}
            inputProps={{ style: { fontSize: 12 } }}
            props={{ style: { fontSize: 12 } }}
          />
        ) : (
          <Paper elevation={0} style={{ maxHeight: 100, overflow: 'auto', backgroundColor: 'inherit' }}>
            <List>
              <Typography variant='caption'>
                <pre
                  style={{
                    fontFamily: 'inherit',
                    margin: 0,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {currentComment}
                </pre>
              </Typography>
            </List>
          </Paper>
        )}
        <RowFlexEnd>
          {isEditing ? (
            <>
              <StyledButton onClick={handleSaveChanges} disabled={!isValidEdit}>
                Save
              </StyledButton>
              <StyledButton onClick={() => handleCancelEdit()} color='error'>
                Cancel
              </StyledButton>
            </>
          ) : (
            <>
              <Tooltip title='Delete' arrow>
                <IconButton size='small' onClick={handleDeleteComment}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit' arrow>
                <IconButton size='small' onClick={() => setIsEditing(true)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </RowFlexEnd>
      </Container>
    </BackgroundPaper>
  );
};

export default MyComment;
