import React from 'react';

import { MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export default function DropdownMenu({ menuValue, handleChange, id, menuItems }) {
  return (
    <SelectMenu variant='standard' disableUnderline labelId={id} id={id} value={menuValue} onChange={handleChange}>
      {menuItems.map((menuItem, idx) => (
        <StyledMenuItem key={`${menuItem.value}-${idx}`} value={menuItem.value}>
          {menuItem.name}
        </StyledMenuItem>
      ))}
    </SelectMenu>
  );
}

const SelectMenu = styled(Select)`
  margin: 0 5px;
  min-width: 100px;
  max-height: 30px;
  background-color: white;
  && {
    font-size: 0.9rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 0.9rem;
  }
`;
