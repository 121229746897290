import React from 'react';

import { Box, Button, Rating, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import theme from '../../theme';
import AddToWatchList from '../MovieDetails/Review/AddToWatchlist';
import { TransparentFullHeightButton } from '../styledComponents/Buttons';
import { CardOverlay, ColumnFlexEnd, RowFlexEnd } from '../styledComponents/Containers';
import Genres from './Genres';

export const EmptyCardMedia = styled(Box)`
  ${(props) => css`
    && {
      height: 100%;
      min-height: 240px;
      background-color: lightgrey;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
    }
  `}
`;

const CardImg = styled.img`
  background-color: ${theme.palette.primary.lightest};
  max-height: 300px;
  min-height: 100%;
  width: 100%;
  border-radius: 3px;
  object-fit: cover;
`;

/** general movie card displaying movie poster, title, rating
 * and add to watchlist button */
function SliderCard({ movie, is_in_watchlist, setIsInWatchlist, state, dispatch }) {
  const navigate = useNavigate();

  const statePayload = {
    title: movie.title,
    image: movie.image,
    rating: movie.rating,
    m_id: movie.m_id,
    year: movie.year,
    genres: movie.genres,
  };

  return (
    <SpacedBox>
      <BookmarkContainer>
        <AddToWatchList
          state={state}
          dispatch={dispatch}
          m_id={movie.m_id}
          statePayload={statePayload}
          is_in_watchlist={is_in_watchlist}
          setIsInWatchlist={setIsInWatchlist}
          isBookmarkIcon={true}
        />
      </BookmarkContainer>
      <MovieImgContainer>
        {movie.image ? (
          <CardImg alt={movie.title} src={movie.image} />
        ) : (
          <EmptyCardMedia>
            <Typography variant='h7' component='div' sx={{ textAlign: 'center' }}>
              {movie.title}
            </Typography>
            <Typography variant='caption' component='div'>
              (No image available)
            </Typography>
          </EmptyCardMedia>
        )}
      </MovieImgContainer>
      <GreyEmptyRating
        fontSize={0.9}
        $isVisible={movie.rating > 0}
        value={movie.rating}
        size='small'
        precision={0.5}
        readOnly
      />
      <CardOverlay>
        <ColumnFlexEnd>
          <TransparentFullHeightButton tabIndex={-1} onClick={() => navigate(`/movie/${movie.m_id}`)} />
          <OverlayDetails>
            <MovieTitle variant='cardCaption'>{movie.title}</MovieTitle>
            <Typography variant='caption'>{movie.year}</Typography>
            <Genres genres={movie.genres} />
            <DetailsButtonContainer>
              <DetailsButton onClick={() => navigate(`/movie/${movie.m_id}`)}>details</DetailsButton>
            </DetailsButtonContainer>
          </OverlayDetails>
        </ColumnFlexEnd>
      </CardOverlay>
    </SpacedBox>
  );
}

export default SliderCard;

const SpacedBox = styled(Box)`
  background-color: ${theme.palette.primary.lightest};
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  border-radius: 3px;
  position: relative;
  min-height: 250px;
  min-width: 160px;
`;

const BookmarkContainer = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  top: -7px;
`;

const OverlayDetails = styled.div`
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
  color: white;
  padding: 10px;
  min-height: max(40%, 120px);
`;

const GreyEmptyRating = styled(Rating)`
  z-index: 5;
  padding-left: 10px;
  &.MuiRating-root {
    font-size: ${(props) => props.fontSize}rem;
  }
  .MuiRating-iconEmpty {
    visibility: ${(props) => (props.$isVisible ? 'visible' : 'hidden')};
    color: ${theme.palette.grey.main};
  }
`;

const DetailsButtonContainer = styled(RowFlexEnd)`
  margin-top: auto;
`;

const DetailsButton = styled.button`
  border: none;
  border-radius: 3px;
  padding: 3px 4px;
  color: ${theme.palette.primary.main};
  text-transform: uppercase;
  cursor: pointer;
`;

const MovieImgContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const MovieTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
