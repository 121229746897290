import React from 'react';

import { Typography } from '@mui/material';
import styled from 'styled-components';

import { swapArrayEl } from '../../util/helper';

import { StyledButton } from '../styledComponents/Buttons';
import LeaderboardBar from './LeaderboardBar';
import UserRanking from './UserRanking';

/** display overall top 5 users and their points tallied from all quizzes */
function Leaderboard({ userList, currUserRanking, setShowLeaderboard, showLeaderboard, isUserInRankings }) {
  const maxScore = userList[0].score;

  const centeredUserList = swapArrayEl(userList, 0, 1);

  // determine the height of each bar
  const barHeights = centeredUserList.map((user) => Math.floor((user.score / maxScore) * 60) + 10);

  return (
    <>
      <BarChartContainer>
        {centeredUserList.map((user, idx) => (
          <LeaderboardBar key={`bar-${idx}`} user={user} idx={idx} barHeights={barHeights} />
        ))}
      </BarChartContainer>

      {!isUserInRankings && (
        <div>
          <Typography variant='h7'>YOUR RANK:</Typography>
          <UserRanking
            showDivider={false}
            rank={currUserRanking.rank}
            username={currUserRanking.username}
            isCurrUser={true}
            points={currUserRanking.score}
          />
        </div>
      )}

      <StyledButton
        variant='contained'
        onClick={() => {
          setShowLeaderboard(!showLeaderboard);
        }}
      >
        Back to quiz
      </StyledButton>
    </>
  );
}

export default Leaderboard;

const BarChartContainer = styled.figure`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  margin: 20px 0;
`;
