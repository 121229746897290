import React from 'react';

import NoResultsText from '../NoResultsText';

function NoRecommendationsText() {
  return (
    <NoResultsText
      mainText={'Movies based on your positive review history will appear here'}
      subtitleText={'To view these recommendations, please rate a movie >= 4 stars'}
    />
  );
}

export default NoRecommendationsText;
