import React, { useEffect, useReducer, useState } from 'react';

import { Box } from '@mui/material';
import styled from 'styled-components';

import { NUM_NOWSHOWING_MOVIES, NUM_RECOMMENDATIONS, TRUE } from '../util/constants';
import { nowshowingDispatchFn, recommendationsDispatchFn, reducer, wishlistDispatchFn } from '../util/reducer';

import BannedList from '../components/BannedList/BannedList';
import CarouselHeader from '../components/Carousel/CarouselHeader';
import NowShowingSliderCard from '../components/Carousel/NowShowingSliderCard';
import SliderCard from '../components/Carousel/SliderCard';
import SnapCarousel from '../components/Carousel/SnapCarousel';
import LoadingBox from '../components/LoadingBox';
import NoResultsText from '../components/NoResultsText';
import QuizInput from '../components/Quiz/QuizInput';
import QuizOptions, { shuffleArray } from '../components/Quiz/QuizOptions';
import QuizSubmitButtons from '../components/Quiz/QuizSubmitButtons';
import NoRecommendationsText from '../components/Recommendations/NoRecommendationsText';
import { BackgroundPaper, Page } from '../components/styledComponents/Containers';

import { fetchNowshowing, fetchRecommendations, fetchWatchlist } from '../api/fetch';
import { makeApiReq } from '../api/internal_api';
import theme from '../theme';
import Quiz from './Quiz';

/**
 * home page displaying quiz section, watchlist,
 * now showing movies, 'for you' recommendations, banned list */
function Home() {
  const username = window.localStorage.getItem('username');
  const initialState = {
    watchlist: [],
    nowShowing: [],
    recommendations: [],
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isWatchlistLoaded, setIsWatchlistLoaded] = useState(false);
  const [isRecLoaded, setIsRecLoaded] = useState(false);

  const [quizPrompt, setQuizPrompt] = useState('');
  const [quizOptions, setQuizOptions] = useState([]);
  const [isQuizAnswered, setIsQuizAnswered] = useState(false);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [userAns, setUserAns] = useState('');

  const [bannedList, setBannedList] = useState([]);
  const [isBannedListLoaded, setIsBannedListLoaded] = useState(false);

  const fetchQuizPrompt = async () => {
    const token = window.localStorage.getItem('token');
    const resp = await makeApiReq('/quiz', null, 'GET', token);
    if (resp.status === 200) {
      const data = await resp.json();
      setQuizPrompt(data.question);
      shuffleArray(data.options);
      setQuizOptions(data.options);
      setIsQuizAnswered(data.user_answered);
    }
  };

  const [quizResult, setQuizResult] = useState({ isCorrect: false, correctAns: '', answerScore: 0 });
  const fetchQuizResult = async () => {
    const token = window.localStorage.getItem('token');
    const payload = { answer: userAns };
    const resp = await makeApiReq('/quiz', payload, 'POST', token);

    if (resp.status === 200) {
      const data = await resp.json();
      setQuizResult({ isCorrect: data.is_correct, correctAns: data.answer, answerScore: data.answer_score });
    }
  };

  useEffect(() => {
    if (userAns) fetchQuizResult();
  }, [userAns]);

  const fetchBannedList = async () => {
    const token = window.localStorage.getItem('token');
    const resp = await makeApiReq('/bannedlist', null, 'GET', token);
    const data = await resp.json();
    setBannedList(data);
    setIsBannedListLoaded(true);
  };

  useEffect(() => {
    fetchWatchlist(null, username, setIsWatchlistLoaded, (data) => wishlistDispatchFn(dispatch, data));
    fetchNowshowing(null, NUM_NOWSHOWING_MOVIES, (data) => nowshowingDispatchFn(dispatch, data));
    fetchRecommendations(null, NUM_RECOMMENDATIONS, TRUE, setIsRecLoaded, (data) =>
      recommendationsDispatchFn(dispatch, data),
    );
    fetchQuizPrompt();
    fetchBannedList();
  }, []);

  const handleRenderSliderCard = (movie) => (
    <SliderCard movie={movie} is_in_watchlist={movie.is_in_watchlist} state={state} dispatch={dispatch} />
  );
  const handleRenderWatchlist = (movie) => (
    <SliderCard movie={movie} is_in_watchlist={movie.is_in_watchlist} state={state} dispatch={dispatch} />
  );

  const handleRenderNowShowingCard = (movie, isInvisible = false) => (
    <NowShowingSliderCard
      isInvisible={isInvisible}
      movie={movie}
      is_in_watchlist={movie.is_in_watchlist}
      state={state}
      dispatch={dispatch}
    />
  );

  const handleQuizInput = () => {
    return quizOptions.length > 1 ? (
      <QuizOptions
        setUserAns={setUserAns}
        userAns={userAns}
        quizPrompt={quizPrompt}
        options={quizOptions}
        setShowLeaderboard={setShowLeaderboard}
        quizResult={quizResult}
      />
    ) : (
      <QuizInput setUserAns={setUserAns} quizPrompt={quizPrompt} setShowLeaderboard={setShowLeaderboard} />
    );
  };

  return (
    <Page>
      <HomeGrid>
        <WatchListGrid>
          {isWatchlistLoaded ? (
            state.watchlist.length !== 0 ? (
              <>
                <CarouselHeader route='watchlist'>Your watchlist</CarouselHeader>
                <SnapCarousel
                  isCarouselLoading={!state.watchlist.length}
                  movieData={state.watchlist}
                  handleRenderCards={handleRenderWatchlist}
                />
              </>
            ) : (
              <BackgroundPaper palette={theme.palette} elevation={2}>
                <NoResultsText mainText={'Your watchlist is currently empty'} subtitleText={'Add movies to watch!'} />
              </BackgroundPaper>
            )
          ) : (
            <LoadingBox />
          )}
        </WatchListGrid>

        <RecommendedGrid>
          {isRecLoaded ? (
            state.recommendations.length !== 0 ? (
              <>
                <CarouselHeader route='recommendations'>Your recommendations</CarouselHeader>
                <SnapCarousel
                  isCarouselLoading={!state.recommendations.length}
                  movieData={state.recommendations}
                  handleRenderCards={handleRenderSliderCard}
                />
              </>
            ) : (
              <BackgroundPaper palette={theme.palette} elevation={2}>
                <NoRecommendationsText />
              </BackgroundPaper>
            )
          ) : (
            <LoadingBox />
          )}
        </RecommendedGrid>

        {quizPrompt && !showLeaderboard && (
          <QuizGrid>
            <BackgroundPaper palette={theme.palette} elevation={2}>
              {isQuizAnswered ? (
                <>
                  <NoResultsText mainText={'Check back tomorrow for another quiz question!'} />
                  <Box padding={'0px 10px 10px'}>
                    <QuizSubmitButtons showLeaderboardOnly={true} setShowLeaderboard={setShowLeaderboard} />
                  </Box>
                </>
              ) : (
                handleQuizInput()
              )}
            </BackgroundPaper>
          </QuizGrid>
        )}

        {showLeaderboard ? (
          <QuizGrid>
            <Quiz quizPrompt={quizPrompt} setShowLeaderboard={setShowLeaderboard} showLeaderboard={showLeaderboard} />
          </QuizGrid>
        ) : (
          <></>
        )}

        <NowShowingGrid>
          {state.nowShowing && (
            <Box>
              <CarouselHeader route='nowshowing'>Nowshowing in cinemas</CarouselHeader>
              <SnapCarousel
                isCarouselLoading={!state.nowShowing.length}
                movieData={state.nowShowing}
                handleRenderCards={handleRenderNowShowingCard}
              />
            </Box>
          )}
        </NowShowingGrid>

        {/*TODO: move banned list to new account settings page
        {isBannedListLoaded ? (
          <BackgroundPaper palette={theme.palette} margin={'10px 60px'} elevation={2}>
            {bannedList.length > 0 ? (
              <BannedList bannedList={bannedList} />
            ) : (
              <NoResultsText
                mainText='Your banned list is currently empty'
                subtitleText='Users that you ban will be displayed here'
              />
            )}
          </BackgroundPaper>
        ) : (
          <LoadingBox />
        )} */}
      </HomeGrid>
    </Page>
  );
}

export default Home;

const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 280px;
  grid-template-areas:
    'watchList watchList quiz'
    'recommended recommended recommended'
    'now now now';
  gap: 10px 10px;
  margin: 10px 20px;
  width: 100%;
`;

const WatchListGrid = styled.div`
  grid-area: watchList;
`;

const QuizGrid = styled.div`
  grid-area: quiz;
`;

const RecommendedGrid = styled.div`
  grid-area: recommended;
`;

const NowShowingGrid = styled.div`
  grid-area: now;
`;
